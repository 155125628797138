/**
 * @title aj-captcha验证码接口*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {}
/**
 * No description
 * @tags aj-captcha验证码接口
 * @name Get
 * @summary 获取验证码接口
 * @request POST:/aj-captcha/get
 */
export function Api_AjCaptchaGet(
  data: BaseIamAccountBCaptchaVO,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBGet["data"]> {
  return request_({
    url: `/base-iam-account-b/aj-captcha/get`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags aj-captcha验证码接口
 * @name Check
 * @summary 核对验证码接口
 * @request POST:/aj-captcha/check
 */
export function Api_AjCaptchaCheck(
  data: BaseIamAccountBCaptchaVO,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBCheck["data"]> {
  return request_({
    url: `/base-iam-account-b/aj-captcha/check`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}
