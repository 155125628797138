import type { Router } from 'vue-router'

export function createDistUpdateNotifyGuard(router: Router) {
  router.beforeEach(() => {
    if (import.meta.env.hot)
      return

    axios
      .create({
        baseURL: import.meta.env.VITE_APP_WEB_BASE,
      })
      .get<BuildInfo>('/build-info.json', { params: { hash: nanoid(4) } })
      .then(({ data }) => {
        if (!window.buildInfo) {
          window.buildInfo = data
        }
        else if (window.buildInfo.lastCommitHash !== data.lastCommitHash) {
          ElMessageBox.confirm($t('login.distUpdatedNote'), '', {
            type: 'success',
          }).then(() => {
            window.location.reload()
          })
        }
      })
  })
}

export interface BuildInfo {
  buildBranch: string
  buildTime: string
  lastCommitTime: string
  lastCommitHash: string
  lastCommitMessage: string
  base: string
  mode: string
  command: string
}

declare global {
  interface Window {
    // build时携带的信息，用于告诉客户端前端构建是否有更新
    buildInfo?: BuildInfo
  }
}
