import { Setting } from '@element-plus/icons-vue'
import type { RouteRecordRaw } from 'vue-router'

import { DefaultLayout } from '../../constants'

const messageCenterRouter: RouteRecordRaw = {
  path: '/ota',
  name: 'Ota',
  component: DefaultLayout,
  redirect: '/ota/package',
  meta: {
    title: $t('ota.name'),
    icon: () => Setting,
  },
  children: [
    {
      path: 'package',
      name: 'OtaPackage',
      meta: {
        title: $t('ota.package.name'),
      },
      component: () => import('@/views/ota/package/index.vue'),
    },
    {
      path: 'task',
      name: 'OtaTask',
      meta: {
        title: $t('ota.task.name'),
      },
      component: () => import('@/views/ota/task/index.vue'),
    },
    {
      path: 'create-task',
      name: 'OtaCreateTask',
      meta: {
        title: $t('commonWords.create'),
        hidden: true,
        singleton: false,
        parentRoutePath: '/ota/task',
        parentTitle: $t('ota.task.name'),
      },
      component: () => import('@/views/ota/task/create-task.vue'),
    },
    {
      path: 'upgrade-record',
      name: 'UpgradeRecord',
      meta: {
        title: $t('ota.upgradeRecord.name'),
      },
      component: () => import('@/views/ota/upgrade-record/index.vue'),
    },
  ],
}

export default messageCenterRouter
