import router from './index'
import { useUserInfoStore } from '@/stores/user-info'

export const jumpToLogin = throttle((warning = true) => {
  const currentRoute = router.currentRoute.value
  if (currentRoute.name !== 'login') {
    const { clearToken } = useUserInfoStore()
    if (warning)
      ElMessage.warning('登录信息校验失败，请重新登录')

    clearToken()
    // 若登录失败则将当前页面信息带到登录页，用于登录后回退
    setTimeout(async () => {
      await router.push({
        name: 'login',
        query: {
          path: encodeURIComponent(currentRoute.path),
          query: currentRoute.query ? json2FormUrlencoded(currentRoute.query).toString() : '',
        },
      })
    }, 1500)
  }
}, 3000)
