/**
 * @title 车型管理接口*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {}
/**
 * No description
 * @tags 车型管理接口
 * @name Update
 * @summary 修改
 * @request POST:/interior/vehicleModel/update
 */
export function Api_InteriorVehicleModelUpdate(
  data: TspBssVehicleModelUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssUpdate["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车型管理接口
 * @name SaveVehicleModel
 * @summary 添加
 * @request POST:/interior/vehicleModel/save
 */
export function Api_InteriorVehicleModelSave(
  data: TspBssVehicleModelSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSaveVehicleModel["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type VehicleModelImportPayload = Parameters<typeof Api_InteriorVehicleModelImport>["data"];
}
/**
 * No description
 * @tags 车型管理接口
 * @name ImportData
 * @summary 导入
 * @request POST:/interior/vehicleModel/import
 */
export function Api_InteriorVehicleModelImport(
  data: TspBssImportDataPayload,
  extraConfig: ExtraConfig = {},
): Promise<TspBssImportData["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/import`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type VehicleModelDelParams = Parameters<typeof Api_InteriorVehicleModelDel>["query"];
}
/**
 * No description
 * @tags 车型管理接口
 * @name Delete
 * @summary 删除
 * @request POST:/interior/vehicleModel/del
 */
export function Api_InteriorVehicleModelDel(
  query: TspBssDeleteParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssDelete["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车型管理接口
 * @name Update1
 * @summary 配置修改
 * @request POST:/interior/vehicleModel/config/update
 */
export function Api_InteriorVehicleModelConfigUpdate(
  data: TspBssVehicleModelConfigAllUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssUpdate1["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/config/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车型管理接口
 * @name Save
 * @summary 配置添加
 * @request POST:/interior/vehicleModel/config/save
 */
export function Api_InteriorVehicleModelConfigSave(
  data: TspBssVehicleModelConfigAllSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSave["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/config/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name UpdateVehicleInfo
 * @summary 修改
 * @request POST:/interior/vehicleInfo/update
 */
export function Api_InteriorVehicleInfoUpdate(
  data: TspBssVehicleInfoUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssUpdateVehicleInfo["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name SaveVehicleInfo
 * @summary 添加
 * @request POST:/interior/vehicleInfo/save
 */
export function Api_InteriorVehicleInfoSave(
  data: TspBssVehicleInfoSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSaveVehicleInfo["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type VehicleInfoImportParams = Parameters<typeof Api_InteriorVehicleInfoImport>["query"];
  export type VehicleInfoImportPayload = Parameters<typeof Api_InteriorVehicleInfoImport>["data"];
}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name ImportData1
 * @summary 导入
 * @request POST:/interior/vehicleInfo/import
 */
export function Api_InteriorVehicleInfoImport(
  query: TspBssImportData1Params,
  data: TspBssImportData1Payload,
  extraConfig: ExtraConfig = {},
): Promise<TspBssImportData1["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/import`,
    method: "POST",
    params: query,
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type VehicleInfoDelParams = Parameters<typeof Api_InteriorVehicleInfoDel>["query"];
}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name DeleteVehicleInfo
 * @summary 删除
 * @request POST:/interior/vehicleInfo/del
 */
export function Api_InteriorVehicleInfoDel(
  query: TspBssDeleteVehicleInfoParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssDeleteVehicleInfo["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags T-BOX管理接口
 * @name Update2
 * @summary 修改
 * @request POST:/interior/tboxInfo/update
 */
export function Api_InteriorTboxInfoUpdate(
  data: TspBssTboxInfoUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssUpdate2["data"]> {
  return request_({
    url: `/tsp-bss/interior/tboxInfo/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags T-BOX管理接口
 * @name Save1
 * @summary 添加
 * @request POST:/interior/tboxInfo/save
 */
export function Api_InteriorTboxInfoSave(
  data: TspBssTboxInfoSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSave1["data"]> {
  return request_({
    url: `/tsp-bss/interior/tboxInfo/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type TboxInfoImportPayload = Parameters<typeof Api_InteriorTboxInfoImport>["data"];
}
/**
 * No description
 * @tags T-BOX管理接口
 * @name ImportData2
 * @summary 导入
 * @request POST:/interior/tboxInfo/import
 */
export function Api_InteriorTboxInfoImport(
  data: TspBssImportData2Payload,
  extraConfig: ExtraConfig = {},
): Promise<TspBssImportData2["data"]> {
  return request_({
    url: `/tsp-bss/interior/tboxInfo/import`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type TboxInfoDelParams = Parameters<typeof Api_InteriorTboxInfoDel>["query"];
}
/**
 * No description
 * @tags T-BOX管理接口
 * @name Delete1
 * @summary 删除
 * @request POST:/interior/tboxInfo/del
 */
export function Api_InteriorTboxInfoDel(
  query: TspBssDelete1Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssDelete1["data"]> {
  return request_({
    url: `/tsp-bss/interior/tboxInfo/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 服务网点内部管理接口
 * @name Update3
 * @summary 修改
 * @request POST:/interior/serviceOutlets/update
 */
export function Api_InteriorServiceOutletsUpdate(
  data: TspBssServiceOutletsUpdateAllReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssUpdate3["data"]> {
  return request_({
    url: `/tsp-bss/interior/serviceOutlets/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 服务网点内部管理接口
 * @name Save2
 * @summary 添加
 * @request POST:/interior/serviceOutlets/save
 */
export function Api_InteriorServiceOutletsSave(
  data: TspBssServiceOutletsSaveAllReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSave2["data"]> {
  return request_({
    url: `/tsp-bss/interior/serviceOutlets/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type ServiceOutletsDelParams = Parameters<typeof Api_InteriorServiceOutletsDel>["query"];
}
/**
 * No description
 * @tags 服务网点内部管理接口
 * @name Delete2
 * @summary 删除
 * @request POST:/interior/serviceOutlets/del
 */
export function Api_InteriorServiceOutletsDel(
  query: TspBssDelete2Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssDelete2["data"]> {
  return request_({
    url: `/tsp-bss/interior/serviceOutlets/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 服务网点内部管理接口
 * @name BatchDel
 * @summary 批量删除
 * @request POST:/interior/serviceOutlets/batchDel
 */
export function Api_InteriorServiceOutletsBatchDel(
  data: TspBssDeleteRequestLong,
  extraConfig: ExtraConfig = {},
): Promise<TspBssBatchDel["data"]> {
  return request_({
    url: `/tsp-bss/interior/serviceOutlets/batchDel`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车系管理接口
 * @name Update4
 * @summary 修改
 * @request POST:/interior/seriesInfo/update
 */
export function Api_InteriorSeriesInfoUpdate(
  data: TspBssSeriesInfoUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssUpdate4["data"]> {
  return request_({
    url: `/tsp-bss/interior/seriesInfo/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车系管理接口
 * @name Save3
 * @summary 添加
 * @request POST:/interior/seriesInfo/save
 */
export function Api_InteriorSeriesInfoSave(
  data: TspBssSeriesInfoSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSave3["data"]> {
  return request_({
    url: `/tsp-bss/interior/seriesInfo/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type SeriesInfoImportPayload = Parameters<typeof Api_InteriorSeriesInfoImport>["data"];
}
/**
 * No description
 * @tags 车系管理接口
 * @name ImportData3
 * @summary 导入
 * @request POST:/interior/seriesInfo/import
 */
export function Api_InteriorSeriesInfoImport(
  data: TspBssImportData3Payload,
  extraConfig: ExtraConfig = {},
): Promise<TspBssImportData3["data"]> {
  return request_({
    url: `/tsp-bss/interior/seriesInfo/import`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type SeriesInfoDelParams = Parameters<typeof Api_InteriorSeriesInfoDel>["query"];
}
/**
 * No description
 * @tags 车系管理接口
 * @name Delete3
 * @summary 删除
 * @request POST:/interior/seriesInfo/del
 */
export function Api_InteriorSeriesInfoDel(
  query: TspBssDelete3Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssDelete3["data"]> {
  return request_({
    url: `/tsp-bss/interior/seriesInfo/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 颜色管理接口
 * @name Update5
 * @summary 修改
 * @request POST:/interior/colorInfo/update
 */
export function Api_InteriorColorInfoUpdate(
  data: TspBssColorInfoUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssUpdate5["data"]> {
  return request_({
    url: `/tsp-bss/interior/colorInfo/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 颜色管理接口
 * @name Save4
 * @summary 添加
 * @request POST:/interior/colorInfo/save
 */
export function Api_InteriorColorInfoSave(
  data: TspBssColorInfoSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSave4["data"]> {
  return request_({
    url: `/tsp-bss/interior/colorInfo/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type ColorInfoDelParams = Parameters<typeof Api_InteriorColorInfoDel>["query"];
}
/**
 * No description
 * @tags 颜色管理接口
 * @name Delete4
 * @summary 删除
 * @request POST:/interior/colorInfo/del
 */
export function Api_InteriorColorInfoDel(
  query: TspBssDelete4Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssDelete4["data"]> {
  return request_({
    url: `/tsp-bss/interior/colorInfo/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 颜色管理接口
 * @name BatchDel1
 * @summary 批量删除
 * @request POST:/interior/colorInfo/batchDel
 */
export function Api_InteriorColorInfoBatchDel(
  data: TspBssDeleteRequestLong,
  extraConfig: ExtraConfig = {},
): Promise<TspBssBatchDel1["data"]> {
  return request_({
    url: `/tsp-bss/interior/colorInfo/batchDel`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 品牌管理接口
 * @name Update6
 * @summary 修改
 * @request POST:/interior/brandInfo/update
 */
export function Api_InteriorBrandInfoUpdate(
  data: TspBssBrandInfoUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssUpdate6["data"]> {
  return request_({
    url: `/tsp-bss/interior/brandInfo/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 品牌管理接口
 * @name Save5
 * @summary 添加
 * @request POST:/interior/brandInfo/save
 */
export function Api_InteriorBrandInfoSave(
  data: TspBssBrandInfoSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSave5["data"]> {
  return request_({
    url: `/tsp-bss/interior/brandInfo/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type BrandInfoDelParams = Parameters<typeof Api_InteriorBrandInfoDel>["query"];
}
/**
 * No description
 * @tags 品牌管理接口
 * @name Delete5
 * @summary 删除
 * @request POST:/interior/brandInfo/del
 */
export function Api_InteriorBrandInfoDel(
  query: TspBssDelete5Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssDelete5["data"]> {
  return request_({
    url: `/tsp-bss/interior/brandInfo/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 品牌管理接口
 * @name BatchDel2
 * @summary 批量删除
 * @request POST:/interior/brandInfo/batchDel
 */
export function Api_InteriorBrandInfoBatchDel(
  data: TspBssDeleteRequestLong,
  extraConfig: ExtraConfig = {},
): Promise<TspBssBatchDel2["data"]> {
  return request_({
    url: `/tsp-bss/interior/brandInfo/batchDel`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type VehicleModelSelectPageParams = Parameters<typeof Api_InteriorVehicleModelSelectPage>["query"];
}
/**
 * No description
 * @tags 车型管理接口
 * @name SelectPage
 * @summary 分页查询
 * @request GET:/interior/vehicleModel/selectPage
 */
export function Api_InteriorVehicleModelSelectPage(
  query: TspBssSelectPageParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectPage["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleModelSelectDownPageParams = Parameters<typeof Api_InteriorVehicleModelSelectDownPage>["query"];
}
/**
 * No description
 * @tags 车型管理接口
 * @name SelectDownPage
 * @summary 下拉列表分页
 * @request GET:/interior/vehicleModel/selectDownPage
 */
export function Api_InteriorVehicleModelSelectDownPage(
  query: TspBssSelectDownPageParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectDownPage["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/selectDownPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleModelSelectColorDownPageParams = Parameters<
    typeof Api_InteriorVehicleModelSelectColorDownPage
  >["query"];
}
/**
 * No description
 * @tags 车型管理接口
 * @name SelectColorDownPage
 * @summary 下拉车型颜色列表分页
 * @request GET:/interior/vehicleModel/selectColorDownPage
 */
export function Api_InteriorVehicleModelSelectColorDownPage(
  query: TspBssSelectColorDownPageParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectColorDownPage["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/selectColorDownPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleModelSelectByIdParams = Parameters<typeof Api_InteriorVehicleModelSelectById>["query"];
}
/**
 * No description
 * @tags 车型管理接口
 * @name SelectById
 * @summary 查询详情
 * @request GET:/interior/vehicleModel/selectById
 */
export function Api_InteriorVehicleModelSelectById(
  query: TspBssSelectByIdParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectById["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleModelExportParams = Parameters<typeof Api_InteriorVehicleModelExport>["query"];
}
/**
 * No description
 * @tags 车型管理接口
 * @name Export
 * @summary 导出
 * @request GET:/interior/vehicleModel/export
 */
export function Api_InteriorVehicleModelExport(
  query: TspBssExportParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssExport["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/export`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车型管理接口
 * @name DownloadTemplate
 * @summary 导入模板下载
 * @request GET:/interior/vehicleModel/downloadTemplate
 */
export function Api_InteriorVehicleModelDownloadTemplate(
  extraConfig: ExtraConfig = {},
): Promise<TspBssDownloadTemplate["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/downloadTemplate`,
    method: "GET",
    ...extraConfig,
  });
}

declare global {
  export type ConfigSelectByModelIdParams = Parameters<typeof Api_InteriorVehicleModelConfigSelectByModelId>["query"];
}
/**
 * No description
 * @tags 车型管理接口
 * @name SelectByModelId
 * @summary 查询车型配置详情
 * @request GET:/interior/vehicleModel/config/selectByModelId
 */
export function Api_InteriorVehicleModelConfigSelectByModelId(
  query: TspBssSelectByModelIdParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectByModelId["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleModel/config/selectByModelId`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleInfoSelectPageParams = Parameters<typeof Api_InteriorVehicleInfoSelectPage>["query"];
}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name SelectPage1
 * @summary 分页查询
 * @request GET:/interior/vehicleInfo/selectPage
 */
export function Api_InteriorVehicleInfoSelectPage(
  query: TspBssSelectPage1Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectPage1["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleInfoSelectByVinParams = Parameters<typeof Api_InteriorVehicleInfoSelectByVin>["query"];
}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name SelectByVin
 * @summary 根据vin查询车辆信息(非脱敏)
 * @request GET:/interior/vehicleInfo/selectByVin
 */
export function Api_InteriorVehicleInfoSelectByVin(
  query: TspBssSelectByVinParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectByVin["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/selectByVin`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleInfoSelectByVidParams = Parameters<typeof Api_InteriorVehicleInfoSelectByVid>["query"];
}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name SelectByVid
 * @summary 根据vid查询车辆信息详情(非脱敏)
 * @request GET:/interior/vehicleInfo/selectByVid
 */
export function Api_InteriorVehicleInfoSelectByVid(
  query: TspBssSelectByVidParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectByVid["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/selectByVid`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleInfoSelectByIdNonDesParams = Parameters<typeof Api_InteriorVehicleInfoSelectByIdNonDes>["query"];
}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name SelectByIdNonDes
 * @summary 根据id查询车辆信息详情(非脱敏)
 * @request GET:/interior/vehicleInfo/selectByIdNonDes
 */
export function Api_InteriorVehicleInfoSelectByIdNonDes(
  query: TspBssSelectByIdNonDesParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectByIdNonDes["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/selectByIdNonDes`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleInfoSelectAllInfoByIdParams = Parameters<typeof Api_InteriorVehicleInfoSelectAllInfoById>["query"];
}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name SelectDetailById
 * @summary 查询车辆全部信息(脱敏)
 * @request GET:/interior/vehicleInfo/selectAllInfoById
 */
export function Api_InteriorVehicleInfoSelectAllInfoById(
  query: TspBssSelectDetailByIdParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectDetailById["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/selectAllInfoById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleInfoSelectAllInfoByIdNonDesParams = Parameters<
    typeof Api_InteriorVehicleInfoSelectAllInfoByIdNonDes
  >["query"];
}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name SelectNoDesAllInfoById
 * @summary 查询车辆全部信息(非脱敏)
 * @request GET:/interior/vehicleInfo/selectAllInfoByIdNonDes
 */
export function Api_InteriorVehicleInfoSelectAllInfoByIdNonDes(
  query: TspBssSelectNoDesAllInfoByIdParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectNoDesAllInfoById["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/selectAllInfoByIdNonDes`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleInfoFuzzySearchPageParams = Parameters<typeof Api_InteriorVehicleInfoFuzzySearchPage>["query"];
}
/**
 * @description 如需回显值排在第一位，id必填
 * @tags 车辆信息管理接口
 * @name FuzzySearchPage
 * @summary vin下拉搜索
 * @request GET:/interior/vehicleInfo/fuzzySearchPage
 */
export function Api_InteriorVehicleInfoFuzzySearchPage(
  query: TspBssFuzzySearchPageParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssFuzzySearchPage["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/fuzzySearchPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type VehicleInfoExportParams = Parameters<typeof Api_InteriorVehicleInfoExport>["query"];
}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name Export1
 * @summary 导出
 * @request GET:/interior/vehicleInfo/export
 */
export function Api_InteriorVehicleInfoExport(
  query: TspBssExport1Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssExport1["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/export`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车辆信息管理接口
 * @name DownloadTemplate1
 * @summary 导入模板下载
 * @request GET:/interior/vehicleInfo/downloadTemplate
 */
export function Api_InteriorVehicleInfoDownloadTemplate(
  extraConfig: ExtraConfig = {},
): Promise<TspBssDownloadTemplate1["data"]> {
  return request_({
    url: `/tsp-bss/interior/vehicleInfo/downloadTemplate`,
    method: "GET",
    ...extraConfig,
  });
}

declare global {
  export type TboxInfoSelectPageParams = Parameters<typeof Api_InteriorTboxInfoSelectPage>["query"];
}
/**
 * No description
 * @tags T-BOX管理接口
 * @name SelectPage2
 * @summary 分页查询
 * @request GET:/interior/tboxInfo/selectPage
 */
export function Api_InteriorTboxInfoSelectPage(
  query: TspBssSelectPage2Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectPage2["data"]> {
  return request_({
    url: `/tsp-bss/interior/tboxInfo/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type TboxInfoSelectByIdParams = Parameters<typeof Api_InteriorTboxInfoSelectById>["query"];
}
/**
 * No description
 * @tags T-BOX管理接口
 * @name SelectById1
 * @summary 查询详情
 * @request GET:/interior/tboxInfo/selectById
 */
export function Api_InteriorTboxInfoSelectById(
  query: TspBssSelectById1Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectById1["data"]> {
  return request_({
    url: `/tsp-bss/interior/tboxInfo/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type TboxInfoSearchBySnParams = Parameters<typeof Api_InteriorTboxInfoSearchBySn>["query"];
}
/**
 * @description 修改下拉回显时，id必填
 * @tags T-BOX管理接口
 * @name SearchBySn
 * @summary tboxSn精确下拉搜索
 * @request GET:/interior/tboxInfo/searchBySn
 */
export function Api_InteriorTboxInfoSearchBySn(
  query: TspBssSearchBySnParams,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSearchBySn["data"]> {
  return request_({
    url: `/tsp-bss/interior/tboxInfo/searchBySn`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type TboxInfoExportParams = Parameters<typeof Api_InteriorTboxInfoExport>["query"];
}
/**
 * No description
 * @tags T-BOX管理接口
 * @name Export2
 * @summary 导出
 * @request GET:/interior/tboxInfo/export
 */
export function Api_InteriorTboxInfoExport(
  query: TspBssExport2Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssExport2["data"]> {
  return request_({
    url: `/tsp-bss/interior/tboxInfo/export`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags T-BOX管理接口
 * @name DownloadTemplate2
 * @summary 导入模板下载
 * @request GET:/interior/tboxInfo/downloadTemplate
 */
export function Api_InteriorTboxInfoDownloadTemplate(
  extraConfig: ExtraConfig = {},
): Promise<TspBssDownloadTemplate2["data"]> {
  return request_({
    url: `/tsp-bss/interior/tboxInfo/downloadTemplate`,
    method: "GET",
    ...extraConfig,
  });
}

declare global {
  export type SimCardSelectPageParams = Parameters<typeof Api_InteriorSimCardSelectPage>["query"];
}
/**
 * No description
 * @tags SIM卡管理接口
 * @name SelectPage3
 * @summary 分页查询
 * @request GET:/interior/simCard/selectPage
 */
export function Api_InteriorSimCardSelectPage(
  query: TspBssSelectPage3Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectPage3["data"]> {
  return request_({
    url: `/tsp-bss/interior/simCard/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type ServiceOutletsSelectPageParams = Parameters<typeof Api_InteriorServiceOutletsSelectPage>["query"];
}
/**
 * No description
 * @tags 服务网点内部管理接口
 * @name SelectPage4
 * @summary 分页查询
 * @request GET:/interior/serviceOutlets/selectPage
 */
export function Api_InteriorServiceOutletsSelectPage(
  query: TspBssSelectPage4Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectPage4["data"]> {
  return request_({
    url: `/tsp-bss/interior/serviceOutlets/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type ServiceOutletsSelectByIdParams = Parameters<typeof Api_InteriorServiceOutletsSelectById>["query"];
}
/**
 * No description
 * @tags 服务网点内部管理接口
 * @name SelectById2
 * @summary 查询详情
 * @request GET:/interior/serviceOutlets/selectById
 */
export function Api_InteriorServiceOutletsSelectById(
  query: TspBssSelectById2Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectById2["data"]> {
  return request_({
    url: `/tsp-bss/interior/serviceOutlets/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type ServiceOutletsExportParams = Parameters<typeof Api_InteriorServiceOutletsExport>["query"];
}
/**
 * No description
 * @tags 服务网点内部管理接口
 * @name Export3
 * @summary 导出
 * @request GET:/interior/serviceOutlets/export
 */
export function Api_InteriorServiceOutletsExport(
  query: TspBssExport3Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssExport3["data"]> {
  return request_({
    url: `/tsp-bss/interior/serviceOutlets/export`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type SeriesInfoSelectPageParams = Parameters<typeof Api_InteriorSeriesInfoSelectPage>["query"];
}
/**
 * No description
 * @tags 车系管理接口
 * @name SelectPage5
 * @summary 分页查询
 * @request GET:/interior/seriesInfo/selectPage
 */
export function Api_InteriorSeriesInfoSelectPage(
  query: TspBssSelectPage5Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectPage5["data"]> {
  return request_({
    url: `/tsp-bss/interior/seriesInfo/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type SeriesInfoSelectDownPageParams = Parameters<typeof Api_InteriorSeriesInfoSelectDownPage>["query"];
}
/**
 * No description
 * @tags 车系管理接口
 * @name SelectDownPage1
 * @summary 下拉列表分页
 * @request GET:/interior/seriesInfo/selectDownPage
 */
export function Api_InteriorSeriesInfoSelectDownPage(
  query: TspBssSelectDownPage1Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectDownPage1["data"]> {
  return request_({
    url: `/tsp-bss/interior/seriesInfo/selectDownPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type SeriesInfoSelectByIdParams = Parameters<typeof Api_InteriorSeriesInfoSelectById>["query"];
}
/**
 * No description
 * @tags 车系管理接口
 * @name SelectById3
 * @summary 查询详情
 * @request GET:/interior/seriesInfo/selectById
 */
export function Api_InteriorSeriesInfoSelectById(
  query: TspBssSelectById3Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectById3["data"]> {
  return request_({
    url: `/tsp-bss/interior/seriesInfo/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type SeriesInfoExportParams = Parameters<typeof Api_InteriorSeriesInfoExport>["query"];
}
/**
 * No description
 * @tags 车系管理接口
 * @name Export4
 * @summary 导出
 * @request GET:/interior/seriesInfo/export
 */
export function Api_InteriorSeriesInfoExport(
  query: TspBssExport4Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssExport4["data"]> {
  return request_({
    url: `/tsp-bss/interior/seriesInfo/export`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 车系管理接口
 * @name DownloadTemplate3
 * @summary 导入模板下载
 * @request GET:/interior/seriesInfo/downloadTemplate
 */
export function Api_InteriorSeriesInfoDownloadTemplate(
  extraConfig: ExtraConfig = {},
): Promise<TspBssDownloadTemplate3["data"]> {
  return request_({
    url: `/tsp-bss/interior/seriesInfo/downloadTemplate`,
    method: "GET",
    ...extraConfig,
  });
}

declare global {
  export type ColorInfoSelectPageParams = Parameters<typeof Api_InteriorColorInfoSelectPage>["query"];
}
/**
 * No description
 * @tags 颜色管理接口
 * @name SelectPage6
 * @summary 分页查询
 * @request GET:/interior/colorInfo/selectPage
 */
export function Api_InteriorColorInfoSelectPage(
  query: TspBssSelectPage6Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectPage6["data"]> {
  return request_({
    url: `/tsp-bss/interior/colorInfo/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type ColorInfoSelectDownPageParams = Parameters<typeof Api_InteriorColorInfoSelectDownPage>["query"];
}
/**
 * No description
 * @tags 颜色管理接口
 * @name SelectDownPage2
 * @summary 下拉列表分页
 * @request GET:/interior/colorInfo/selectDownPage
 */
export function Api_InteriorColorInfoSelectDownPage(
  query: TspBssSelectDownPage2Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectDownPage2["data"]> {
  return request_({
    url: `/tsp-bss/interior/colorInfo/selectDownPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type ColorInfoSelectByIdParams = Parameters<typeof Api_InteriorColorInfoSelectById>["query"];
}
/**
 * No description
 * @tags 颜色管理接口
 * @name SelectById4
 * @summary 查询详情
 * @request GET:/interior/colorInfo/selectById
 */
export function Api_InteriorColorInfoSelectById(
  query: TspBssSelectById4Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectById4["data"]> {
  return request_({
    url: `/tsp-bss/interior/colorInfo/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type BrandInfoSelectPageParams = Parameters<typeof Api_InteriorBrandInfoSelectPage>["query"];
}
/**
 * No description
 * @tags 品牌管理接口
 * @name SelectPage7
 * @summary 分页查询
 * @request GET:/interior/brandInfo/selectPage
 */
export function Api_InteriorBrandInfoSelectPage(
  query: TspBssSelectPage7Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectPage7["data"]> {
  return request_({
    url: `/tsp-bss/interior/brandInfo/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type BrandInfoSelectDownPageParams = Parameters<typeof Api_InteriorBrandInfoSelectDownPage>["query"];
}
/**
 * No description
 * @tags 品牌管理接口
 * @name SelectDownPage3
 * @summary 下拉列表分页
 * @request GET:/interior/brandInfo/selectDownPage
 */
export function Api_InteriorBrandInfoSelectDownPage(
  query: TspBssSelectDownPage3Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectDownPage3["data"]> {
  return request_({
    url: `/tsp-bss/interior/brandInfo/selectDownPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type BrandInfoSelectByIdParams = Parameters<typeof Api_InteriorBrandInfoSelectById>["query"];
}
/**
 * No description
 * @tags 品牌管理接口
 * @name SelectById5
 * @summary 查询详情
 * @request GET:/interior/brandInfo/selectById
 */
export function Api_InteriorBrandInfoSelectById(
  query: TspBssSelectById5Params,
  extraConfig: ExtraConfig = {},
): Promise<TspBssSelectById5["data"]> {
  return request_({
    url: `/tsp-bss/interior/brandInfo/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}
