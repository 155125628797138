import { Setting } from '@element-plus/icons-vue'
import type { RouteRecordRaw } from 'vue-router'

import { DefaultLayout } from '../../constants'

const monitoringRouter: RouteRecordRaw = {
  path: '/monitoring',
  name: 'Monitoring',
  component: DefaultLayout,
  redirect: '/monitoring/real-time',
  meta: {
    title: $t('monitoring.name'),
    icon: () => Setting,
  },
  children: [
    {
      path: 'map',
      name: 'MonitoringMap',
      meta: {
        title: $t('monitoring.map.name'),
      },
      component: () => import('@/views/monitoring/map/index.vue'),
    },
    {
      path: 'alarm',
      name: 'MonitoringAlarm',
      meta: {
        title: $t('monitoring.alarm.name'),
      },
      component: () => import('@/views/monitoring/alarm/index.vue'),
    },
    {
      path: 'alarm-records',
      name: 'MonitoringAlarmRecords',
      meta: {
        title: $t('monitoring.alarm.alarmRecords.name'),
        hidden: true,
        singleton: true,
        parentRoutePath: '/monitoring/alarm',
        parentTitle: $t('monitoring.alarm.name'),
      },
      component: () => import('@/views/monitoring/alarm/alarm-records.vue'),
    },
    {
      path: 'failure-report',
      name: 'MonitoringFailureReport',
      meta: {
        title: $t('monitoring.failureReport.name'),
      },
      component: () => import('@/views/monitoring/failure-report/index.vue'),
    },
    {
      path: 'real-time',
      name: 'MonitoringRealTime',
      meta: {
        title: $t('monitoring.realTime.name'),
      },
      component: () => import('@/views/monitoring/real-time/index.vue'),
    },
    {
      path: 'trajectory-playback',
      name: 'MonitoringTrajectoryPlayback',
      meta: {
        title: $t('monitoring.trajectoryPlayback.name'),
      },
      component: () => import('@/views/monitoring/trajectory-playback/index.vue'),
    },
    {
      path: 'fence-info',
      name: 'MonitoringFenceInfo',
      meta: {
        title: $t('monitoring.fenceInfo.name'),
      },
      component: () => import('@/views/monitoring/fence-info/index.vue'),
    },
    {
      path: 'fence-alarm',
      name: 'MonitoringFenceAlarm',
      meta: {
        title: $t('monitoring.fenceAlarm.name'),
      },
      component: () => import('@/views/monitoring/fence-alarm/index.vue'),
    },
  ],
}

export default monitoringRouter
