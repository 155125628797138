import type { Router } from 'vue-router'

import { useLayoutStore } from './layout-store'

export function createLayoutGuard(router: Router) {
  router.beforeEach((to) => {
    const { pageLoading, activePath } = storeToRefs(useLayoutStore())
    if (!to.meta.hidden)
      activePath.value = to.fullPath

    pageLoading.value = true
  })
  router.afterEach(() => {
    const { pageLoading } = storeToRefs(useLayoutStore())
    pageLoading.value = false
  })
}
