import { emptyHomePath, getCommonViews } from 'nx-layouts/common-views'
import { createRouter, createWebHistory } from 'vue-router'

import { DefaultLayout } from './constants'
import { createGuard } from './guards'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'root',
      component: DefaultLayout,
      redirect: emptyHomePath,
      meta: {
        fullPath: emptyHomePath,
        title: $t('commonWords.homePage'),
        hideBreadCrumb: true,
        hidden: true,
      },
      children: [...getCommonViews()],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/index.vue'),
      meta: {
        title: $t('login.name'),
      },
    },
  ], // 其他路由在packages/utils/access中添加
})
createGuard(router)
export default router
