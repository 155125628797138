import { useEnumsStore } from './store.tsx'

// 供外部调用注册枚举
export function registerEnum(key: EnumGroupKey | DataDictionaryCode, group: UniversalOption[]) {
  const { setGroup } = useEnumsStore()
  setGroup(key, group)
  console.log(chalk.green(`枚举[${key}]注册成功`))
}

export function batchRegisterEnums(configs: Record<EnumGroupKey, UniversalOption[]>) {
  const { setGroup } = useEnumsStore()
  Object.entries(configs).forEach(([key, group]) => {
    setGroup(key, group)
  })
  console.log(chalk.green(`枚举[${Object.keys(configs)}]注册成功`))
}
