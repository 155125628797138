import { Setting } from '@element-plus/icons-vue'
import type { RouteRecordRaw } from 'vue-router'

import { DefaultLayout } from '../../constants'

const vehicleManagementRouter: RouteRecordRaw = {
  path: '/vehicle-management',
  name: 'vehicle-management',
  component: DefaultLayout,
  redirect: '/vehicle-management/vehicle',
  meta: {
    title: $t('vehicleManagement.name'),
    icon: () => Setting,
  },
  children: [
    {
      path: 'vehicle',
      name: 'VehicleManagementVehicle',
      meta: {
        title: $t('vehicleManagement.name'),
      },
      component: () => import('@/views/vehicle-management/vehicle/index.vue'),
    },
    {
      path: 'vehicle-detail',
      name: 'VehicleManagementVehicleDetail',
      meta: {
        title: $t('vehicleManagement.vehicle.info'),
        hidden: true,
        singleton: false,
        parentRoutePath: '/vehicle-management/vehicle',
        parentTitle: $t('vehicleManagement.name'),
      },
      component: () => import('@/views/vehicle-management/vehicle/detail.vue'),
    },
    {
      path: 'binding-record',
      name: 'VehicleManagementBindingRecord',
      meta: {
        title: $t('vehicleManagement.bindingRecord.name'),
      },
      component: () => import('@/views/vehicle-management/binding-record/index.vue'),
    },
    {
      path: 'diagnose-record',
      name: 'VehicleManagementDiagnoseRecord',
      meta: {
        title: $t('vehicleManagement.diagnoseRecord.name'),
      },
      component: () => import('@/views/vehicle-management/diagnose-record/index.vue'),
    },
    {
      path: 'remote-control-record',
      name: 'VehicleManagementRemoteControlRecord',
      meta: {
        title: $t('vehicleManagement.remoteControlRecord.name'),
      },
      component: () => import('@/views/vehicle-management/remote-control-record/index.vue'),
    },
  ],
}

export default vehicleManagementRouter
