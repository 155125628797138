type GetRequest = (data: BaseIamAccountBCaptchaVO) => Promise<BaseIamAccountBGetUsingPost['data']>
type CheckRequest = (
  data: BaseIamAccountBCaptchaVO,
) => Promise<BaseIamAccountBCheckUsingPost['data']>
interface CaptchaRequests { get: GetRequest, check: CheckRequest }

// 为滑块验证注册请求方法
export function registerCaptchaRequests(option: CaptchaRequests) {
  window.captchaRequests = option
  console.log(chalk.green('滑块校验请求方法注册成功'))
}

declare global {
  interface Window {
    captchaRequests?: CaptchaRequests
  }
}
