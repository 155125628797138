import { emptyHomePath } from 'nx-layouts/common-views'
import type { RouteRecordRaw, Router } from 'vue-router'

import { useLayoutTabsStore } from './store'
import type { RoutLocationRaw } from './types'
import { asyncRoutes } from '@/router/routes'

const whiteList = ['/login', emptyHomePath, '/404', '/401']
const splitter = '/_win_/'

export function createTabsGuard(router: Router) {
  // 路由跳转前添加动态生成新的路由记录
  router.beforeEach((to) => {
    if (
      whiteList.includes(to.path) // 若在白名单中
      || to.meta.runtimeCreated // 或本身就是由本守卫自动新建的实例
    ) {
      // 则本守卫不进行额外操作
      return
    }

    const { layoutTabs, tabLoading } = storeToRefs(useLayoutTabsStore())
    // 若是layoutTabs中没有找到对应的记录，即这个路由还没有被创建和初始化，则执行初始化实例
    if (layoutTabs.value.every(v => v.fullPath !== to.fullPath)) {
      tabLoading.value = true
      const { name, path } = addNewRoute(to, router)
      if (path)
        return { path }

      const { query, params } = to

      return { name, query, params }
    }
  })
  // 路由跳转后添加tab
  router.afterEach((to) => {
    const { params, query, fullPath, meta, name, path } = to
    const { originName, title, singleton } = meta || {}
    const layoutTabsStore = useLayoutTabsStore()
    const { addTab, activeHome } = layoutTabsStore
    if (whiteList.includes(fullPath) || whiteList.includes(path)) {
      if (fullPath === emptyHomePath)
        return activeHome(router, false)

      return
    }

    addTab({
      active: true,
      closeable: true,
      draggable: true,
      contextDisable: false,
      params,
      query,
      fullPath,
      singleton,
      originName: singleton === false ? originName : undefined,
      routeName: name as string,
      title,
    })
  })

  const _push = router.push

  // 重写router的push方法配合tab
  router.push = async (to: RoutLocationRaw) => {
    const _to = cloneDeep(to)
    const routes = router.getRoutes()
    // 若没有找到路由记录则新增路由记录
    if (routes.every(v => v.path !== _to.path && v.name !== _to.name)) {
      const { name, path } = addNewRoute(_to, router)
      _to.name = name
      _to.path = path as string
      if (!path)
        delete _to.path
    }
    return _push.bind(router)(_to)
  }

  const _back = router.back
  // 重写router的back方法配合tab
  router.back = () => {
    useLayoutTabsStore().removeCurrentTab(router)

    return _back.bind(router)()
  }
}

// 根据目的地添加路由记录
export function addNewRoute(to: RoutLocationRaw, router: Router): { path?: string, originName?: string, name?: string, title?: string } {
  // 根据分割关键字从url中分离出硬编码路由表中匹配的path
  const rawPath = to.path?.split(splitter).shift() || ''
  // 硬盘编码中的父路由记录初始值
  const parentRouteRecordRaw = asyncRoutes.find((v) => {
    return v.children?.some(
      x =>
        // 硬编码的父子路由路径拼接找到实际的父路由记录
        `${v.path}/${x.path}` === rawPath
        // 或者根据传入的name分割匹配路由记录
        || x.name === to.name?.toString().split(splitter).shift(),
    )
  })
  // 硬盘编码中的路由记录初始值
  const originRouteRecordRaw = parentRouteRecordRaw?.children?.find(
    v =>
      // 将路径中的父路径去除并匹配实际子路由
      v.path === rawPath.replace(`${parentRouteRecordRaw?.path}/`, '')
      // 或者根据传入的name分割匹配路由记录
      || v.name === to.name?.toString().split(splitter).shift(),
  )

  // 若没有匹配到子路由则跳转到404
  if (
    !originRouteRecordRaw
    || !parentRouteRecordRaw
    // 若没有权限则不添加路由 跳转到404
    || !hasAccess(originRouteRecordRaw.meta!.fullPath!)
  )
    return { path: '/404' }

  // 是否是页面单例，默认为单例
  const singleton = originRouteRecordRaw.meta?.singleton !== false
  // 获取随机值
  const newNo = nanoid(5)
  // 新的页面组件名和路由记录名
  const newName = singleton
    ? <string>originRouteRecordRaw.name
    : <string>originRouteRecordRaw.name + splitter + newNo
  // 新的路由路径
  const newPath = singleton
    ? originRouteRecordRaw.path
    : originRouteRecordRaw.path + splitter + newNo

  // 新待添加的路由记录
  const newRoute: RouteRecordRaw = {
    path: `${parentRouteRecordRaw.path}/${newPath}`,
    name: newName,
    meta: {
      ...originRouteRecordRaw.meta,
      runtimeCreated: true,
      originName: originRouteRecordRaw.name as string,
    },
    component: createPageRouteComponent(newName, originRouteRecordRaw.component),
  }
  // 添加路由记录
  router.addRoute(parentRouteRecordRaw.name as string, newRoute)

  return {
    originName: originRouteRecordRaw.name as string,
    name: newName,
    title: originRouteRecordRaw.meta!.title,
  }
}
