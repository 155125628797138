import AddressSelector from './src/address-selector.vue'

export * from './src/helper'
export * from './src/types'
export * from './src/store'
export * from './src/UniMap'
export * from './src/UniMarker'
export * from './src/UniPolyline'

export const NxAddressSelector = withInstall(AddressSelector)
