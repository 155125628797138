let gmpGeocoder: google.maps.Geocoder
let amapGeocoder: AMap.Geocoder
export async function getDetailByLatLng(_position: google.maps.LatLngLiteral): Promise<{
  address: string
} & google.maps.LatLngLiteral> {
  if (isCnBuild()) {
    amapGeocoder = amapGeocoder || new AMap.Geocoder({})
    return new Promise((resolve, reject) => {
      amapGeocoder.getAddress(new AMap.LngLat(_position.lng, _position.lat), (status: AMap.Geocoder.SearchStatus, result: AMap.Geocoder.ReGeocodeResult) => {
        if (status === 'complete') {
          resolve({
            ..._position,
            address: result.regeocode.formattedAddress,
          })
        }
        else {
          reject(result.info)
        }
      })
    })
  }
  else {
    gmpGeocoder = gmpGeocoder || new google.maps.Geocoder()
    const { results } = await gmpGeocoder.geocode({ location: _position })
    return {
      ..._position,
      address: results[0].formatted_address,
    }
  }
}
