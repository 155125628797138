import './style.scss'

export const Dialog = defineComponent({
  props: {
    cancelText: {
      type: [Boolean, String],
      default: $t('components.confirm.cancelButtonText'),
    },
    confirmText: {
      type: [Boolean, String],
      default: $t('components.confirm.confirmButtonText'),
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    beforeClose: {
      type: Function as PropType<(done: (...args: any[]) => any) => void>,
      default: () => (done: (...args: any[]) => any) => done(),
    },
    title: {
      type: String,
      default: '',
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    bodyPadding: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  expose: ['handleClose'],
  emits: ['close', 'confirm', 'update:visible'],
  setup(props, { emit, attrs, slots }) {
    const visible = computed({
      get: () => props.visible,
      set: newVal => emit('update:visible', newVal),
    })
    const handleClose = () => {
      if (typeof props.beforeClose === 'function')
        props.beforeClose(() => (visible.value = false))
      else
        visible.value = false

      emit('close')
    }
    const handleCancel = () => (visible.value = false)
    const handleConfirm = throttle(() => emit('confirm'), 2000, {
      leading: true,
      trailing: false,
    })

    const { clearValidate } = useFormInstanceGather()

    // 如果隐藏的话就去掉弹窗内的表单校验
    watch(
      () => visible.value,
      (newVal) => {
        if (!newVal)
          clearValidate()
      },
    )

    return () => (
      <el-dialog
        ref="nxDialog"
        v-model={visible.value}
        {...attrs}
        close-on-click-modal={false}
        append-to-body
        show-close={props.showClose}
        class={[
          'nx-dialog',
          !props.cancelText && !props.confirmText ? 'no-footer-btn' : '',
          props.bodyPadding ? '' : 'nx-dialog__no_body_padding',
        ]}
        onClose={handleClose}
      >
        {{
          header: () => (
            <div>
              <span style="font-size: 16px; font-weight: bold">{props.title}</span>
            </div>
          ),
          default: slots.default,
          footer: props.showFooter && (props.cancelText || props.confirmText)
            ? () => (
              <span class="nx-dialog-footer">
                {slots.footer?.({ cancel: handleCancel, confirm: handleConfirm }) ?? (
                  <>
                    {props.cancelText && (
                      <el-button onClick={handleCancel}>{props.cancelText}</el-button>
                    )}
                    {props.confirmText && (
                      <el-button type="primary" onClick={handleConfirm}>
                        {props.confirmText}
                      </el-button>
                    )}
                  </>
                )}
              </span>
              )
            : undefined,
        }}
      </el-dialog>
    )
  },
})
