import { Setting } from '@element-plus/icons-vue'
import type { RouteRecordRaw } from 'vue-router'

import { DefaultLayout } from '../../constants'

const operationsRouter: RouteRecordRaw = {
  path: '/operations',
  name: 'operations',
  component: DefaultLayout,
  redirect: '/operations/agreements',
  meta: {
    title: $t('operations.name'),
    icon: () => Setting,
  },
  children: [
    {
      path: 'app-user',
      name: 'OperationsAppUser',
      meta: {
        title: $t('operations.appUser.name'),
      },
      component: () => import('@/views/operations/app-user/index.vue'),
    },
    {
      path: 'agreements',
      name: 'OperationsAgreements',
      meta: {
        title: $t('operations.agreements.name'),
      },
      component: () => import('@/views/operations/agreements/index.vue'),
    },
    {
      path: 'create-agreement',
      name: 'OperationsCreateAgreement',
      meta: {
        title: $t('operations.agreements.create'),
        hidden: true,
        singleton: false,
        parentRoutePath: '/operations/agreements',
        parentTitle: $t('operations.agreements.name'),
      },
      component: () => import('@/views/operations/agreements/create-agreement.vue'),
    },
    {
      path: 'agreement-detail',
      name: 'OperationsAgreementDetail',
      meta: {
        title: $t('operations.agreements.detail'),
        hidden: true,
        singleton: false,
        parentRoutePath: '/operations/agreements',
        parentTitle: $t('operations.agreements.name'),
      },
      component: () => import('@/views/operations/agreements/create-agreement.vue'),
    },
    {
      path: 'agreements-record',
      name: 'OperationsAgreementsRecord',
      meta: {
        title: $t('operations.agreements.authorizationRecord'),
      },
      component: () => import('@/views/operations/agreements/record.vue'),
    },
    {
      path: 'file-export',
      name: 'OperationsFileExport',
      meta: {
        title: $t('operations.fileExport.name'),
      },
      component: () => import('@/views/operations/file-export/index.vue'),
    },
    {
      path: 'app-lang',
      name: 'OperationsAppLang',
      meta: {
        title: $t('operations.locales.appLocales'),
      },
      component: () => import('@/views/operations/app-lang/index.vue'),
    },
    {
      path: 'admin-lang',
      name: 'OperationsAdminLang',
      meta: {
        title: $t('operations.locales.adminLocales'),
      },
      component: () => import('@/views/operations/admin-lang/index.vue'),
    },
    {
      path: 'user-account-change',
      name: 'OperationsUserAccountChange',
      meta: {
        title: $t('operations.appUser.phoneChangeRecord'),
      },
      component: () => import('@/views/operations/user-account-change/index.vue'),
    },
    {
      path: 'user-login-record',
      name: 'OperationsUserLoginRecord',
      meta: {
        title: $t('operations.userLoginRecord.name'),
      },
      component: () => import('@/views/operations/user-login-record/index.vue'),
    },
  ],
}

export default operationsRouter
