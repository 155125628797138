/**
 * @title 故障码管理接口*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {}
/**
 * No description
 * @tags 故障码管理接口
 * @name Update
 * @summary 修改
 * @request POST:/interior/dtcCode/update
 */
export function Api_InteriorDtcCodeUpdate(
  data: TspAlertDtcCodeAllUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertUpdate["data"]> {
  return request_({
    url: `/tsp-alert/interior/dtcCode/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 报警项管理接口
 * @name Update1
 * @summary 修改
 * @request POST:/interior/alertCodeExt/update
 */
export function Api_InteriorAlertCodeExtUpdate(
  data: TspAlertAlertCodeExtUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertUpdate1["data"]> {
  return request_({
    url: `/tsp-alert/interior/alertCodeExt/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 报警项管理接口
 * @name Save
 * @summary 添加
 * @request POST:/interior/alertCodeExt/save
 */
export function Api_InteriorAlertCodeExtSave(
  data: TspAlertAlertCodeExtSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSave["data"]> {
  return request_({
    url: `/tsp-alert/interior/alertCodeExt/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type AlertCodeExtDelParams = Parameters<typeof Api_InteriorAlertCodeExtDel>["query"];
}
/**
 * No description
 * @tags 报警项管理接口
 * @name Delete
 * @summary 删除
 * @request POST:/interior/alertCodeExt/del
 */
export function Api_InteriorAlertCodeExtDel(
  query: TspAlertDeleteParams,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertDelete["data"]> {
  return request_({
    url: `/tsp-alert/interior/alertCodeExt/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 报警项管理接口
 * @name BatchDel
 * @summary 批量删除
 * @request POST:/interior/alertCodeExt/batchDel
 */
export function Api_InteriorAlertCodeExtBatchDel(
  data: TspAlertDeleteRequestLong,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertBatchDel["data"]> {
  return request_({
    url: `/tsp-alert/interior/alertCodeExt/batchDel`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type FaultEventSelectPageParams = Parameters<typeof Api_InteriorFaultEventSelectPage>["query"];
}
/**
 * No description
 * @tags 故障事件管理接口
 * @name SelectPage
 * @summary 分页查询
 * @request GET:/interior/faultEvent/selectPage
 */
export function Api_InteriorFaultEventSelectPage(
  query: TspAlertSelectPageParams,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSelectPage["data"]> {
  return request_({
    url: `/tsp-alert/interior/faultEvent/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type FaultEventSelectByIdParams = Parameters<typeof Api_InteriorFaultEventSelectById>["query"];
}
/**
 * No description
 * @tags 故障事件管理接口
 * @name SelectById
 * @summary 查询详情
 * @request GET:/interior/faultEvent/selectById
 */
export function Api_InteriorFaultEventSelectById(
  query: TspAlertSelectByIdParams,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSelectById["data"]> {
  return request_({
    url: `/tsp-alert/interior/faultEvent/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type FaultEventExportParams = Parameters<typeof Api_InteriorFaultEventExport>["query"];
}
/**
 * No description
 * @tags 故障事件管理接口
 * @name Export
 * @summary 导出
 * @request GET:/interior/faultEvent/export
 */
export function Api_InteriorFaultEventExport(
  query: TspAlertExportParams,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertExport["data"]> {
  return request_({
    url: `/tsp-alert/interior/faultEvent/export`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type DtcCodeSelectPageParams = Parameters<typeof Api_InteriorDtcCodeSelectPage>["query"];
}
/**
 * No description
 * @tags 故障码管理接口
 * @name SelectPage1
 * @summary 分页查询
 * @request GET:/interior/dtcCode/selectPage
 */
export function Api_InteriorDtcCodeSelectPage(
  query: TspAlertSelectPage1Params,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSelectPage1["data"]> {
  return request_({
    url: `/tsp-alert/interior/dtcCode/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type DtcCodeSelectByIdParams = Parameters<typeof Api_InteriorDtcCodeSelectById>["query"];
}
/**
 * No description
 * @tags 故障码管理接口
 * @name SelectById1
 * @summary 查询详情
 * @request GET:/interior/dtcCode/selectById
 */
export function Api_InteriorDtcCodeSelectById(
  query: TspAlertSelectById1Params,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSelectById1["data"]> {
  return request_({
    url: `/tsp-alert/interior/dtcCode/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 故障码管理接口
 * @name GetDtcLabel
 * @summary 查询故障码表字典
 * @request GET:/interior/dtcCode/getDtcLabel
 */
export function Api_InteriorDtcCodeGetDtcLabel(extraConfig: ExtraConfig = {}): Promise<TspAlertGetDtcLabel["data"]> {
  return request_({
    url: `/tsp-alert/interior/dtcCode/getDtcLabel`,
    method: "GET",
    ...extraConfig,
  });
}

declare global {
  export type DtcCodeExportParams = Parameters<typeof Api_InteriorDtcCodeExport>["query"];
}
/**
 * No description
 * @tags 故障码管理接口
 * @name Export1
 * @summary 导出
 * @request GET:/interior/dtcCode/export
 */
export function Api_InteriorDtcCodeExport(
  query: TspAlertExport1Params,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertExport1["data"]> {
  return request_({
    url: `/tsp-alert/interior/dtcCode/export`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AlertCodeExtSelectPageParams = Parameters<typeof Api_InteriorAlertCodeExtSelectPage>["query"];
}
/**
 * No description
 * @tags 报警项管理接口
 * @name SelectPage2
 * @summary 分页查询
 * @request GET:/interior/alertCodeExt/selectPage
 */
export function Api_InteriorAlertCodeExtSelectPage(
  query: TspAlertSelectPage2Params,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSelectPage2["data"]> {
  return request_({
    url: `/tsp-alert/interior/alertCodeExt/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AlertCodeExtSelectByIdParams = Parameters<typeof Api_InteriorAlertCodeExtSelectById>["query"];
}
/**
 * No description
 * @tags 报警项管理接口
 * @name SelectById2
 * @summary 查询详情
 * @request GET:/interior/alertCodeExt/selectById
 */
export function Api_InteriorAlertCodeExtSelectById(
  query: TspAlertSelectById2Params,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSelectById2["data"]> {
  return request_({
    url: `/tsp-alert/interior/alertCodeExt/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 报警项管理接口
 * @name SelectAll
 * @summary 查询所有报警项
 * @request GET:/interior/alertCodeExt/selectAll
 */
export function Api_InteriorAlertCodeExtSelectAll(extraConfig: ExtraConfig = {}): Promise<TspAlertSelectAll["data"]> {
  return request_({
    url: `/tsp-alert/interior/alertCodeExt/selectAll`,
    method: "GET",
    ...extraConfig,
  });
}

declare global {
  export type AlarmReportSelectPageParams = Parameters<typeof Api_InteriorAlarmReportSelectPage>["query"];
}
/**
 * No description
 * @tags 报警上报记录管理接口
 * @name SelectPage3
 * @summary 分页查询
 * @request GET:/interior/alarmReport/selectPage
 */
export function Api_InteriorAlarmReportSelectPage(
  query: TspAlertSelectPage3Params,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSelectPage3["data"]> {
  return request_({
    url: `/tsp-alert/interior/alarmReport/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AlarmReportExportParams = Parameters<typeof Api_InteriorAlarmReportExport>["query"];
}
/**
 * No description
 * @tags 报警上报记录管理接口
 * @name Export2
 * @summary 导出
 * @request GET:/interior/alarmReport/export
 */
export function Api_InteriorAlarmReportExport(
  query: TspAlertExport2Params,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertExport2["data"]> {
  return request_({
    url: `/tsp-alert/interior/alarmReport/export`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AlarmChangeSelectPageParams = Parameters<typeof Api_InteriorAlarmChangeSelectPage>["query"];
}
/**
 * No description
 * @tags 报警上报管理接口
 * @name SelectPage4
 * @summary 分页查询
 * @request GET:/interior/alarmChange/selectPage
 */
export function Api_InteriorAlarmChangeSelectPage(
  query: TspAlertSelectPage4Params,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSelectPage4["data"]> {
  return request_({
    url: `/tsp-alert/interior/alarmChange/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AlarmChangeSelectByIdParams = Parameters<typeof Api_InteriorAlarmChangeSelectById>["query"];
}
/**
 * No description
 * @tags 报警上报管理接口
 * @name SelectById3
 * @summary 查询详情
 * @request GET:/interior/alarmChange/selectById
 */
export function Api_InteriorAlarmChangeSelectById(
  query: TspAlertSelectById3Params,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSelectById3["data"]> {
  return request_({
    url: `/tsp-alert/interior/alarmChange/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AlarmChangeSelectByIdNoDesParams = Parameters<typeof Api_InteriorAlarmChangeSelectByIdNoDes>["query"];
}
/**
 * No description
 * @tags 报警上报管理接口
 * @name SelectByIdNoDes
 * @summary 查询详情(非脱敏)
 * @request GET:/interior/alarmChange/selectByIdNoDes
 */
export function Api_InteriorAlarmChangeSelectByIdNoDes(
  query: TspAlertSelectByIdNoDesParams,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertSelectByIdNoDes["data"]> {
  return request_({
    url: `/tsp-alert/interior/alarmChange/selectByIdNoDes`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AlarmChangeExportParams = Parameters<typeof Api_InteriorAlarmChangeExport>["query"];
}
/**
 * No description
 * @tags 报警上报管理接口
 * @name Export3
 * @summary 导出
 * @request GET:/interior/alarmChange/export
 */
export function Api_InteriorAlarmChangeExport(
  query: TspAlertExport3Params,
  extraConfig: ExtraConfig = {},
): Promise<TspAlertExport3["data"]> {
  return request_({
    url: `/tsp-alert/interior/alarmChange/export`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}
