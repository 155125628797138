// 格式化日期
export function formatDate(date: Date | number | string, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!date)
    return ''

  return dayjs(date).format(format) as string
}

// 合并对象默认值
export function assignDefault<T extends Record<string, any>>(curVal: Partial<T>, defaultVal: Partial<T>) {
  const keys: (keyof T)[] = Object.keys(curVal).concat(Object.keys(defaultVal))
  const result: Partial<T> = {}
  keys.forEach((key) => {
    result[key] = curVal[key] ?? defaultVal[key]
  })
  return result as Required<T>
}

// 移除空属性
export function removeEmptyProp<T extends Record<string, unknown>>(obj: T): Partial<T> {
  obj = cloneDeep(obj)
  Object.entries(obj).forEach(([key, value]) => {
    if (['', null, undefined].includes(value as never))
      delete obj[key]
  })
  return obj
}

// 移除值为undefined的属性
export function removeUndefinedProp<T extends Record<string, unknown>>(obj: T): Partial<T> {
  obj = cloneDeep(obj)
  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined)
      delete obj[key]
  })
  return obj
}

// 获取文件路径中的文件名
export function getFileName(path: string, withExtension?: boolean) {
  const nameWithExtension = path.split('\\').pop()?.split('/').pop()
  return (withExtension ? nameWithExtension : nameWithExtension?.split('.').shift()) || ''
}

// json对象转成 application/x-www-form-urlencoded
export function json2FormUrlencoded(json: Record<string, any>): URLSearchParams {
  const params = new URLSearchParams()
  Object.keys(json)
    .sort()
    .forEach((key) => {
      params.append(key, json[key])
    })
  return params
}

// json对象转成 application/multipart/form-data
export function json2FormData(json: Record<string, any>): FormData {
  const formData = new FormData()
  Object.keys(json).forEach((key) => {
    formData.append(key, json[key])
  })
  return formData
}

/**
 * @description 使用指定字符填充数据到指定长度
 * @param data 源数据
 * @param options
 * @param options.length {number} 目标长度
 * @param options.char {string} 填充字符串
 * @param options.method {'prepend' | 'append'} 在前面添加还是在后面添加
 */
export function paddingStr(data: never, options: {
  length: number
  char?: string
  method?: 'prepend' | 'append'
}): string {
  const { length, char = '0', method = 'prepend' } = options
  const str = String(data)
  const lackLength = length - str.length
  if (lackLength <= 0)
    return str

  const _paddingStr = Array.from({ length: lackLength })
    .fill(char)
    .join('')
    .slice(0 - lackLength)

  return method === 'prepend' ? _paddingStr + str : str + _paddingStr
}

// 合并由import.meta.glob导入的modules到一个对象
export function aggregateModuleExports<T = unknown>(modules: Recordable<Recordable<T>>, fileNameAsDefault = true) {
  return Object.entries(modules).reduce((previousValue, [filePath, exports]) => {
    const fileName = filePath.split('/').pop()!.split('.').shift()
    exports = { ...exports }
    if (fileName && fileNameAsDefault && exports.default) {
      exports[fileName] = exports.default
      delete exports.default
    }
    return Object.assign(previousValue, exports)
  }, {})
}

// 获取字符串的渲染宽度，单位px
// export function getStrWidth(str = '', option?: {
//   fullWidthCharWidth?: number // 全角字符单字字宽
//   halfWidthCharWidth?: number // 半角字符单字字宽
//   adjust?: number // 宽度微调
// }) {
//   const _option = assignDefault(option || {}, {
//     halfWidthCharWidth: 9,
//     fullWidthCharWidth: 14,
//     adjust: 30,
//   })

//   const fullWidthCharCount
//     = str?.toString().match(
//       /[\uFF01-\uFF5E\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF\u3200-\u32FF\u3300-\u33FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF]/g,
//     )?.length || 0 // 全角字符长度
//   // const halfWidthCharCount = str?.match(/[ -~]/g)?.length || 0; // 半角符号长度
//   const otherCount = str?.length - fullWidthCharCount // 其他字符长度

//   return (
//     fullWidthCharCount * _option.fullWidthCharWidth
//     + otherCount * _option.halfWidthCharWidth
//     + _option.adjust // 微调补偿
//   )
// }
export function getStrWidth(str: string, font?: string) {
  const canvas = document.createElement('canvas')
  const content = canvas.getContext('2d') as CanvasRenderingContext2D
  content.font = `${font || '14px '}arial`
  const { width } = content.measureText(str)
  return width
}

// 将对象拍平成 {'key1.key2.key3': 'value'}的形式
export function flattenObject(obj: Recordable<any>, path = '', result: Recordable<any> = {}) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null)
      flattenObject(obj[key], `${path}${key}.`, result)
    else
      result[path + key] = obj[key]
  }
  return result
}

// 为异步导入的组件重新命名
export function renameAsyncImport(component: any, name: string) {
  component.default.name = name
  return component
}

// 入参时间距离当前时间几[秒、分钟、小时、月、年]前，具体单位取最大的
export function getTimeAgo(timestamp: number | string | Date) {
  const current = dayjs()
  const past = dayjs(timestamp)
  const compareArr = [
    { unit: 'second', boundary: 60, suffix: '秒' },
    { unit: 'minute', boundary: 60, suffix: '分钟' },
    { unit: 'hour', boundary: 24, suffix: '小时' },
    { unit: 'day', boundary: 30, suffix: '天' },
    { unit: 'month', boundary: 12, suffix: '个月' },
    { unit: 'year', boundary: Number.POSITIVE_INFINITY, suffix: '年' },
  ] as const

  for (let i = 0; i < compareArr.length; i++) {
    const config = compareArr[i]
    const interval = current.diff(past, config.unit)
    if (interval < config.boundary)
      return `${interval}${config.suffix}前`
  }
}
