/**
 * @title PC语言包扩展接口*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {}
/**
 * No description
 * @tags PC语言包扩展接口
 * @name Update5
 * @summary 修改
 * @request POST:/interior/langPackExt/update
 */
export function Api_InteriorLangPackExtUpdate(
  data: BaseIncCenterLangPackExtUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterUpdate5["data"]> {
  return request_({
    url: `/base-inc-center/interior/langPackExt/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags PC语言包管理接口
 * @name Update6
 * @summary 修改
 * @request POST:/interior/langPack/update
 */
export function Api_InteriorLangPackUpdate(
  data: BaseIncCenterLangPackUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterUpdate6["data"]> {
  return request_({
    url: `/base-inc-center/interior/langPack/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags PC语言包管理接口
 * @name SaveLangPack
 * @summary 添加
 * @request POST:/interior/langPack/save
 */
export function Api_InteriorLangPackSave(
  data: BaseIncCenterLangPackSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSaveLangPack["data"]> {
  return request_({
    url: `/base-inc-center/interior/langPack/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type LangPackDelParams = Parameters<typeof Api_InteriorLangPackDel>["query"];
}
/**
 * No description
 * @tags PC语言包管理接口
 * @name Delete
 * @summary 删除
 * @request POST:/interior/langPack/del
 */
export function Api_InteriorLangPackDel(
  query: BaseIncCenterDeleteParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterDelete["data"]> {
  return request_({
    url: `/base-inc-center/interior/langPack/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags PC语言基础数据维护接口
 * @name SaveLangBase
 * @summary 添加
 * @request POST:/interior/langBase/save
 */
export function Api_InteriorLangBaseSave(
  data: BaseIncCenterLangBaseSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSaveLangBase["data"]> {
  return request_({
    url: `/base-inc-center/interior/langBase/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type LangBaseDelParams = Parameters<typeof Api_InteriorLangBaseDel>["query"];
}
/**
 * No description
 * @tags PC语言基础数据维护接口
 * @name Delete1
 * @summary 删除
 * @request POST:/interior/langBase/del
 */
export function Api_InteriorLangBaseDel(
  query: BaseIncCenterDelete1Params,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterDelete1["data"]> {
  return request_({
    url: `/base-inc-center/interior/langBase/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type LangPackExtSelectPageParams = Parameters<typeof Api_InteriorLangPackExtSelectPage>["query"];
}
/**
 * No description
 * @tags PC语言包扩展接口
 * @name SelectPage6
 * @summary 分页查询
 * @request GET:/interior/langPackExt/selectPage
 */
export function Api_InteriorLangPackExtSelectPage(
  query: BaseIncCenterSelectPage6Params,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectPage6["data"]> {
  return request_({
    url: `/base-inc-center/interior/langPackExt/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type LangPackExtSelectByIdParams = Parameters<typeof Api_InteriorLangPackExtSelectById>["query"];
}
/**
 * No description
 * @tags PC语言包扩展接口
 * @name SelectById
 * @summary 查询详情
 * @request GET:/interior/langPackExt/selectById
 */
export function Api_InteriorLangPackExtSelectById(
  query: BaseIncCenterSelectByIdParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectById["data"]> {
  return request_({
    url: `/base-inc-center/interior/langPackExt/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type LangPackSelectPageParams = Parameters<typeof Api_InteriorLangPackSelectPage>["query"];
}
/**
 * No description
 * @tags PC语言包管理接口
 * @name SelectPage7
 * @summary 分页查询
 * @request GET:/interior/langPack/selectPage
 */
export function Api_InteriorLangPackSelectPage(
  query: BaseIncCenterSelectPage7Params,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectPage7["data"]> {
  return request_({
    url: `/base-inc-center/interior/langPack/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags PC语言包管理接口
 * @name SelectList
 * @summary 查询语言包列表
 * @request GET:/interior/langPack/selectList
 */
export function Api_InteriorLangPackSelectList(
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectList["data"]> {
  return request_({
    url: `/base-inc-center/interior/langPack/selectList`,
    method: "GET",
    ...extraConfig,
  });
}

declare global {
  export type LangPackSelectByIdParams = Parameters<typeof Api_InteriorLangPackSelectById>["query"];
}
/**
 * No description
 * @tags PC语言包管理接口
 * @name SelectById1
 * @summary 查询详情
 * @request GET:/interior/langPack/selectById
 */
export function Api_InteriorLangPackSelectById(
  query: BaseIncCenterSelectById1Params,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectById1["data"]> {
  return request_({
    url: `/base-inc-center/interior/langPack/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type LangPackGetLangPackMapParams = Parameters<typeof Api_InteriorLangPackGetLangPackMap>["query"];
}
/**
 * No description
 * @tags PC语言包管理接口
 * @name GetLangPackMap
 * @summary 查询语言包Map详情
 * @request GET:/interior/langPack/getLangPackMap
 */
export function Api_InteriorLangPackGetLangPackMap(
  query: BaseIncCenterGetLangPackMapParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterGetLangPackMap["data"]> {
  return request_({
    url: `/base-inc-center/interior/langPack/getLangPackMap`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 语言code接口
 * @name SelectList1
 * @summary 查询语言code列表
 * @request GET:/interior/langCode/selectList
 */
export function Api_InteriorLangCodeSelectList(
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectList1["data"]> {
  return request_({
    url: `/base-inc-center/interior/langCode/selectList`,
    method: "GET",
    ...extraConfig,
  });
}

declare global {
  export type LangBaseSelectPageParams = Parameters<typeof Api_InteriorLangBaseSelectPage>["query"];
}
/**
 * No description
 * @tags PC语言基础数据维护接口
 * @name SelectPage8
 * @summary 分页查询
 * @request GET:/interior/langBase/selectPage
 */
export function Api_InteriorLangBaseSelectPage(
  query: BaseIncCenterSelectPage8Params,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectPage8["data"]> {
  return request_({
    url: `/base-inc-center/interior/langBase/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AreaDataSelectListParams = Parameters<typeof Api_InteriorAreaDataSelectList>["query"];
}
/**
 * No description
 * @tags B端区域数据接口
 * @name SelectList2
 * @summary 查询区域数据列表(查询手机区号不传参,查询国内省份 areaType = zh ,查询国际区域信息 areaType = other ,默认以header里的lang值作为语言查询条件,需要特定语言则传参lang)
 * @request GET:/interior/areaData/selectList
 */
export function Api_InteriorAreaDataSelectList(
  query: BaseIncCenterSelectList2Params,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectList2["data"]> {
  return request_({
    url: `/base-inc-center/interior/areaData/selectList`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}
