const isCn = isCnBuild()

export class UniPolygon {
  private gmpInstance?: google.maps.Polygon
  private aMapInstance?: AMap.Polygon
  private map?: any
  private editor?: any
  constructor(props: {
    path: google.maps.LatLngLiteral[]
    map: google.maps.Map | AMap.Map
  }, options?: {
    focus?: boolean
  }) {
    this.map = props.map
    if (isCn) {
      this.aMapInstance = new AMap.Polygon({
        path: props.path.map(({ lat, lng }) => [lng, lat]), // 路径
        fillColor: '#ccebc5', // 多边形填充颜色，使用16进制颜色代码赋值，如：#00B2D5
        strokeOpacity: 1, // 轮廓线透明度，取值范围 [0,1] ，0表示完全透明，1表示不透明。默认为0.9
        fillOpacity: 0.5, // 多边形填充透明度，取值范围 [0,1] ，0表示完全透明，1表示不透明。默认为0.5
        strokeColor: '#2b8cbe', // 线条颜色，使用16进制颜色代码赋值。默认值为#00D3FC
        strokeWeight: 1, // 轮廓线宽度
        strokeStyle: 'dashed', // 轮廓线样式，实线:solid，虚线:dashed
        strokeDasharray: [5, 5], // 勾勒形状轮廓的虚线和间隙的样式
        ...options,
      })
      this.map.add(this.aMapInstance)
      this.map.setFitView([this.aMapInstance])
    }
    else {
      this.gmpInstance = new google.maps.Polygon({
        paths: props.path,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: this.map,
        ...options,
      })
    }
  }

  polygonEditor(callback: ((arg0: google.maps.LatLngLiteral[]) => void) | undefined) {
    if (isCn) {
      this.editor = new AMap.PolyEditor(this.map, this.aMapInstance)
      this.editor.open()
      this.editor.on('adjust', () => {
        if (callback)
          callback(this.getPath())
      })
    }
    else {
      this.gmpInstance?.setOptions({
        draggable: true,
        editable: true,
      })
      // 点击路径点移除
      this.gmpInstance?.addListener('click', (ev: { vertex: number | undefined }) => {
        const length = this.gmpInstance?.getPath().getLength() ?? 0
        if (typeof ev.vertex === 'number' && length > 2)
          this.gmpInstance?.getPath().removeAt(ev.vertex)
      })
      // 监听路径变化
      this.gmpInstance?.getPath().addListener('insert_at', () => {
        if (callback)
          callback(this.getPath())
      })
      this.gmpInstance?.getPath().addListener('remove_at', () => {
        if (callback)
          callback(this.getPath())
      })
      this.gmpInstance?.getPath().addListener('set_at', () => {
        if (callback)
          callback(this.getPath())
      })
    }
  }

  getPath() {
    if (isCn) {
      const aMapPath = this.aMapInstance.getPath() as google.maps.LatLngLiteral[]
      return aMapPath.map(({ lat, lng }: google.maps.LatLngLiteral) => ({ lat, lng }))
    }
    else {
      const gMapPath = []
      const vertices = this.gmpInstance?.getPath() as google.maps.MVCArray<google.maps.LatLng>
      for (let i = 0; i < vertices.getLength(); i++) {
        const xy = vertices.getAt(i)
        gMapPath.push({ lat: xy.lat(), lng: xy.lng() })
      }
      return gMapPath as google.maps.LatLngLiteral[]
    }
  }

  destroy() {
    if (isCn) {
      this.editor.close()
      this.map.remove(this.aMapInstance)
    }
    else {
      this.gmpInstance?.setMap(null)
      google.maps.event.clearListeners(this.gmpInstance!, 'insert_at')
      google.maps.event.clearListeners(this.gmpInstance!, 'remove_at')
      google.maps.event.clearListeners(this.gmpInstance!, 'set_at')
      google.maps.event.clearListeners(this.gmpInstance!, 'click')
      this.gmpInstance = undefined
    }
  }
}
