/**
 * @title 用户管理*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name Unregister
 * @summary 注销
 * @request POST:/users/unregister
 */
export function Api_UsersUnregister(extraConfig: ExtraConfig = {}): Promise<BaseIamAccountBUnregister["data"]> {
  return request_({
    url: `/base-iam-account-b/users/unregister`,
    method: "POST",
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name UnregisterApply
 * @summary 注销申请
 * @request POST:/users/unregisterApply
 */
export function Api_UsersUnregisterApply(
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBUnregisterApply["data"]> {
  return request_({
    url: `/base-iam-account-b/users/unregisterApply`,
    method: "POST",
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name SetPassword
 * @summary 新用户未设置密码时设置密码
 * @request POST:/users/setPassword
 */
export function Api_UsersSetPassword(
  data: BaseIamAccountBUserSetPassword,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBSetPassword["data"]> {
  return request_({
    url: `/base-iam-account-b/users/setPassword`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name ModifyPassword
 * @summary 修改密码
 * @request POST:/users/modifyPassword
 */
export function Api_UsersModifyPassword(
  data: BaseIamAccountBUserModifyPassword,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBModifyPassword["data"]> {
  return request_({
    url: `/base-iam-account-b/users/modifyPassword`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name ForgetPassword
 * @summary 忘记密码
 * @request POST:/users/forgetPassword
 */
export function Api_UsersForgetPassword(
  data: BaseIamAccountBUserResetPassword,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBForgetPassword["data"]> {
  return request_({
    url: `/base-iam-account-b/users/forgetPassword`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name CheckPassword
 * @summary 校验密码
 * @request POST:/users/checkPassword
 */
export function Api_UsersCheckPassword(
  data: BaseIamAccountBUserCheckPassword,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBCheckPassword["data"]> {
  return request_({
    url: `/base-iam-account-b/users/checkPassword`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name ChangePhone
 * @summary 更换手机号
 * @request POST:/users/changePhone
 */
export function Api_UsersChangePhone(
  data: BaseIamAccountBUserChangePhone,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBChangePhone["data"]> {
  return request_({
    url: `/base-iam-account-b/users/changePhone`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name ChangeMail
 * @summary 更换邮箱
 * @request POST:/users/changeMail
 */
export function Api_UsersChangeMail(
  data: BaseIamAccountBUserChangeMail,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBChangeMail["data"]> {
  return request_({
    url: `/base-iam-account-b/users/changeMail`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name BindPhone
 * @summary 绑定手机号
 * @request POST:/users/bindPhone
 */
export function Api_UsersBindPhone(
  data: BaseIamAccountBUserBindPhone,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBBindPhone["data"]> {
  return request_({
    url: `/base-iam-account-b/users/bindPhone`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name BindMail
 * @summary 绑定邮箱
 * @request POST:/users/bindMail
 */
export function Api_UsersBindMail(
  data: BaseIamAccountBUserBindMail,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBBindMail["data"]> {
  return request_({
    url: `/base-iam-account-b/users/bindMail`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name UpdateUserInfo
 * @summary 编辑用户信息
 * @request POST:/users/admin/updateUserInfo
 */
export function Api_UsersAdminUpdateUserInfo(
  data: BaseIamAccountBUserInfoUpdateVo,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBUpdateUserInfo["data"]> {
  return request_({
    url: `/base-iam-account-b/users/admin/updateUserInfo`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name UpdatePhone
 * @summary 修改手机号
 * @request POST:/users/admin/updatePhone
 */
export function Api_UsersAdminUpdatePhone(
  data: BaseIamAccountBUpdatePhoneVo,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBUpdatePhone["data"]> {
  return request_({
    url: `/base-iam-account-b/users/admin/updatePhone`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name UpdateEnable
 * @summary 修改启用禁用状态
 * @request POST:/users/admin/updateEnable
 */
export function Api_UsersAdminUpdateEnable(
  data: BaseIamAccountBUpdateEnableVo,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBUpdateEnable["data"]> {
  return request_({
    url: `/base-iam-account-b/users/admin/updateEnable`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name UnregisterApply1
 * @summary 注销申请
 * @request POST:/users/admin/unregisterApply
 */
export function Api_UsersAdminUnregisterApply(
  data: BaseIamAccountBUserUnregisterReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBUnregisterApply1["data"]> {
  return request_({
    url: `/base-iam-account-b/users/admin/unregisterApply`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name ResetPassword
 * @summary 重置密码
 * @request POST:/users/admin/resetPassword
 */
export function Api_UsersAdminResetPassword(
  data: BaseIamAccountBResetPasswordVo,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBResetPassword["data"]> {
  return request_({
    url: `/base-iam-account-b/users/admin/resetPassword`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name Register
 * @summary 新增用户信息
 * @request POST:/users/admin/insertUserInfo
 */
export function Api_UsersAdminInsertUserInfo(
  data: BaseIamAccountBUserRegister,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBRegister["data"]> {
  return request_({
    url: `/base-iam-account-b/users/admin/insertUserInfo`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type AdminDeleteUserParams = Parameters<typeof Api_UsersAdminDeleteUser>["query"];
}
/**
 * No description
 * @tags 用户管理
 * @name DeleteUser
 * @summary 删除
 * @request POST:/users/admin/deleteUser
 */
export function Api_UsersAdminDeleteUser(
  query: BaseIamAccountBDeleteUserParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBDeleteUser["data"]> {
  return request_({
    url: `/base-iam-account-b/users/admin/deleteUser`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AdminDeleteInfoParams = Parameters<typeof Api_UsersAdminDeleteInfo>["query"];
}
/**
 * No description
 * @tags 用户管理
 * @name DeleteInfo
 * @summary 用户注销处理关联信息
 * @request POST:/users/admin/deleteInfo
 */
export function Api_UsersAdminDeleteInfo(
  query: BaseIamAccountBDeleteInfoParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBDeleteInfo["data"]> {
  return request_({
    url: `/base-iam-account-b/users/admin/deleteInfo`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name SignOut
 * @summary 退出登录
 * @request GET:/users/signOut
 */
export function Api_UsersSignOut(extraConfig: ExtraConfig = {}): Promise<BaseIamAccountBSignOut["data"]> {
  return request_({
    url: `/base-iam-account-b/users/signOut`,
    method: "GET",
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 用户管理
 * @name Current
 * @summary 当前用户
 * @request GET:/users/current
 */
export function Api_UsersCurrent(extraConfig: ExtraConfig = {}): Promise<BaseIamAccountBCurrent["data"]> {
  return request_({
    url: `/base-iam-account-b/users/current`,
    method: "GET",
    ...extraConfig,
  });
}

declare global {
  export type AdminFindUserByUserPhoneParams = Parameters<typeof Api_UsersAdminFindUserByUserPhone>["query"];
}
/**
 * No description
 * @tags 用户管理
 * @name FindUserByUserPhone
 * @summary 根据用户手机号查询用户信息
 * @request GET:/users/admin/findUserByUserPhone
 */
export function Api_UsersAdminFindUserByUserPhone(
  query: BaseIamAccountBFindUserByUserPhoneParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBFindUserByUserPhone["data"]> {
  return request_({
    url: `/base-iam-account-b/users/admin/findUserByUserPhone`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AdminFindUserByUserIdParams = Parameters<typeof Api_UsersAdminFindUserByUserId>["query"];
}
/**
 * No description
 * @tags 用户管理
 * @name FindUserByUserId
 * @summary 根据用户id查询用户信息
 * @request GET:/users/admin/findUserByUserId
 */
export function Api_UsersAdminFindUserByUserId(
  query: BaseIamAccountBFindUserByUserIdParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIamAccountBFindUserByUserId["data"]> {
  return request_({
    url: `/base-iam-account-b/users/admin/findUserByUserId`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}
