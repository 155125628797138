const isCn = isCnBuild()
export class UniMarker {
  private gmpInstance?: google.maps.Marker
  private aMapInstance?: AMap.Marker
  constructor(props: {
    position: google.maps.LatLngLiteral
    icon?: google.maps.Icon | google.maps.Symbol
    map?: google.maps.Map | AMap.Map
  }, options?: {
    focus?: boolean
  }) {
    if (isCn) {
      const position = props.position ? new AMap.LngLat(props.position.lng, props.position.lat) : undefined
      this.aMapInstance = new AMap.Marker({
        position,
        offset: new AMap.Pixel(-30, -40),
        icon: new AMap.Icon({
          size: new AMap.Size(60, 60),
          image: props.icon?.url,
          imageSize: new AMap.Size(60, 60),
        }),
      })
      if (props.map)
        this.setMap(props.map)
      if (props.map && position && options?.focus)
        (props.map as AMap.Map).setCenter(position)
    }
    else {
      this.gmpInstance = new google.maps.Marker({ icon: props.icon })
      if (props.map)
        this.setMap(props.map)

      if (props.position)
        this.position = props.position
      if (props.map && props.position && options?.focus)
        (props.map as google.maps.Map).setCenter(props.position)
    }
  }

  setMap(map: google.maps.Map | AMap.Map | null) {
    if (isCn)
      this.aMapInstance!.setMap(map as AMap.Map)

    else
      this.gmpInstance!.setMap(map as google.maps.Map)
  }

  get position(): google.maps.LatLngLiteral | undefined {
    if (isCn) {
      const _position = this.aMapInstance!.getPosition()
      return _position
        ? {
            lat: _position.getLat(),
            lng: _position.getLng(),
          }
        : undefined
    }
    else {
      return this.gmpInstance!.getPosition()?.toJSON()
    }
  }

  set position(position: google.maps.LatLngLiteral) {
    if (isCn) {
      this.aMapInstance!.setPosition(new AMap.LngLat(position.lng, position.lat))
    }
    else {
      this.gmpInstance!.setPosition({
        lat: Number(position.lat),
        lng: Number(position.lng),
      })
    }
  }

  setOptions(options: google.maps.MarkerOptions) {
    this.gmpInstance?.setOptions(options)
  }

  get marker() {
    return this.gmpInstance
  }
}
