/**
 * @title APP语言包扩展接口*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {}
/**
 * No description
 * @tags APP语言包扩展接口
 * @name Update
 * @summary 修改
 * @request POST:/interior/langPackExt/update
 */
export function Api_InteriorLangPackExtUpdate(
  data: AppOptLangPackExtUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<AppOptUpdate["data"]> {
  return request_({
    url: `/app-opt/interior/langPackExt/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags APP语言包管理接口
 * @name UpdateLangPack
 * @summary 修改
 * @request POST:/interior/langPack/update
 */
export function Api_InteriorLangPackUpdate(
  data: AppOptLangPackUpdateReq,
  extraConfig: ExtraConfig = {},
): Promise<AppOptUpdateLangPack["data"]> {
  return request_({
    url: `/app-opt/interior/langPack/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags APP语言包管理接口
 * @name SaveLangPack
 * @summary 添加
 * @request POST:/interior/langPack/save
 */
export function Api_InteriorLangPackSave(
  data: AppOptLangPackSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSaveLangPack["data"]> {
  return request_({
    url: `/app-opt/interior/langPack/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags APP语言包管理接口
 * @name Publish
 * @summary 发布
 * @request POST:/interior/langPack/publish
 */
export function Api_InteriorLangPackPublish(
  data: AppOptLangPackPublishReq,
  extraConfig: ExtraConfig = {},
): Promise<AppOptPublish["data"]> {
  return request_({
    url: `/app-opt/interior/langPack/publish`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type LangPackDelParams = Parameters<typeof Api_InteriorLangPackDel>["query"];
}
/**
 * No description
 * @tags APP语言包管理接口
 * @name Delete
 * @summary 删除
 * @request POST:/interior/langPack/del
 */
export function Api_InteriorLangPackDel(
  query: AppOptDeleteParams,
  extraConfig: ExtraConfig = {},
): Promise<AppOptDelete["data"]> {
  return request_({
    url: `/app-opt/interior/langPack/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags APP语言基础数据维护接口
 * @name SaveLangBase
 * @summary 添加
 * @request POST:/interior/langBase/save
 */
export function Api_InteriorLangBaseSave(
  data: AppOptLangBaseSaveReq,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSaveLangBase["data"]> {
  return request_({
    url: `/app-opt/interior/langBase/save`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type LangBaseDelParams = Parameters<typeof Api_InteriorLangBaseDel>["query"];
}
/**
 * No description
 * @tags APP语言基础数据维护接口
 * @name Delete1
 * @summary 删除
 * @request POST:/interior/langBase/del
 */
export function Api_InteriorLangBaseDel(
  query: AppOptDelete1Params,
  extraConfig: ExtraConfig = {},
): Promise<AppOptDelete1["data"]> {
  return request_({
    url: `/app-opt/interior/langBase/del`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags B端-协议接口
 * @name Update1
 * @summary 修改协议
 * @request POST:/interior/agreement/update
 */
export function Api_InteriorAgreementUpdate(
  data: AppOptAgreementUpdateDto,
  extraConfig: ExtraConfig = {},
): Promise<AppOptUpdate1["data"]> {
  return request_({
    url: `/app-opt/interior/agreement/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type AgreementPublishParams = Parameters<typeof Api_InteriorAgreementPublish>["query"];
}
/**
 * No description
 * @tags B端-协议接口
 * @name Publish1
 * @summary 发布
 * @request POST:/interior/agreement/publish
 */
export function Api_InteriorAgreementPublish(
  query: AppOptPublish1Params,
  extraConfig: ExtraConfig = {},
): Promise<AppOptPublish1["data"]> {
  return request_({
    url: `/app-opt/interior/agreement/publish`,
    method: "POST",
    params: query,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags B端-协议接口
 * @name Add
 * @summary 新增协议
 * @request POST:/interior/agreement/add
 */
export function Api_InteriorAgreementAdd(
  data: AppOptAgreementAddDto,
  extraConfig: ExtraConfig = {},
): Promise<AppOptAdd["data"]> {
  return request_({
    url: `/app-opt/interior/agreement/add`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type LangPackExtSelectPageParams = Parameters<typeof Api_InteriorLangPackExtSelectPage>["query"];
}
/**
 * No description
 * @tags APP语言包扩展接口
 * @name SelectPage
 * @summary 分页查询
 * @request GET:/interior/langPackExt/selectPage
 */
export function Api_InteriorLangPackExtSelectPage(
  query: AppOptSelectPageParams,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSelectPage["data"]> {
  return request_({
    url: `/app-opt/interior/langPackExt/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type LangPackExtSelectByIdParams = Parameters<typeof Api_InteriorLangPackExtSelectById>["query"];
}
/**
 * No description
 * @tags APP语言包扩展接口
 * @name SelectById
 * @summary 查询详情
 * @request GET:/interior/langPackExt/selectById
 */
export function Api_InteriorLangPackExtSelectById(
  query: AppOptSelectByIdParams,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSelectById["data"]> {
  return request_({
    url: `/app-opt/interior/langPackExt/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type LangPackSelectPageParams = Parameters<typeof Api_InteriorLangPackSelectPage>["query"];
}
/**
 * No description
 * @tags APP语言包管理接口
 * @name SelectPage1
 * @summary 分页查询
 * @request GET:/interior/langPack/selectPage
 */
export function Api_InteriorLangPackSelectPage(
  query: AppOptSelectPage1Params,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSelectPage1["data"]> {
  return request_({
    url: `/app-opt/interior/langPack/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type LangPackSelectByIdParams = Parameters<typeof Api_InteriorLangPackSelectById>["query"];
}
/**
 * No description
 * @tags APP语言包管理接口
 * @name SelectById1
 * @summary 查询详情
 * @request GET:/interior/langPack/selectById
 */
export function Api_InteriorLangPackSelectById(
  query: AppOptSelectById1Params,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSelectById1["data"]> {
  return request_({
    url: `/app-opt/interior/langPack/selectById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type LangBaseSelectPageParams = Parameters<typeof Api_InteriorLangBaseSelectPage>["query"];
}
/**
 * No description
 * @tags APP语言基础数据维护接口
 * @name SelectPage2
 * @summary 分页查询
 * @request GET:/interior/langBase/selectPage
 */
export function Api_InteriorLangBaseSelectPage(
  query: AppOptSelectPage2Params,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSelectPage2["data"]> {
  return request_({
    url: `/app-opt/interior/langBase/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AgreementUserSelectPageParams = Parameters<typeof Api_InteriorAgreementUserSelectPage>["query"];
}
/**
 * No description
 * @tags B端-用户协议记录接口
 * @name SelectPage3
 * @summary 分页查询协议记录
 * @request GET:/interior/agreementUser/selectPage
 */
export function Api_InteriorAgreementUserSelectPage(
  query: AppOptSelectPage3Params,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSelectPage3["data"]> {
  return request_({
    url: `/app-opt/interior/agreementUser/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AgreementUserSelectByIdNonDesParams = Parameters<
    typeof Api_InteriorAgreementUserSelectByIdNonDes
  >["query"];
}
/**
 * No description
 * @tags B端-用户协议记录接口
 * @name SelectByIdNonDes
 * @summary 根据id查询详情非脱敏
 * @request GET:/interior/agreementUser/selectByIdNonDes
 */
export function Api_InteriorAgreementUserSelectByIdNonDes(
  query: AppOptSelectByIdNonDesParams,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSelectByIdNonDes["data"]> {
  return request_({
    url: `/app-opt/interior/agreementUser/selectByIdNonDes`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AgreementSelectPageParams = Parameters<typeof Api_InteriorAgreementSelectPage>["query"];
}
/**
 * No description
 * @tags B端-协议接口
 * @name SelectPage4
 * @summary 分页查询协议列表
 * @request GET:/interior/agreement/selectPage
 */
export function Api_InteriorAgreementSelectPage(
  query: AppOptSelectPage4Params,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSelectPage4["data"]> {
  return request_({
    url: `/app-opt/interior/agreement/selectPage`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type AgreementSelectDetailByIdParams = Parameters<typeof Api_InteriorAgreementSelectDetailById>["query"];
}
/**
 * No description
 * @tags B端-协议接口
 * @name SelectDetailById
 * @summary 查询详情
 * @request GET:/interior/agreement/selectDetailById
 */
export function Api_InteriorAgreementSelectDetailById(
  query: AppOptSelectDetailByIdParams,
  extraConfig: ExtraConfig = {},
): Promise<AppOptSelectDetailById["data"]> {
  return request_({
    url: `/app-opt/interior/agreement/selectDetailById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}
