import { Setting } from '@element-plus/icons-vue'
import type { RouteRecordRaw } from 'vue-router'

import { DefaultLayout } from '../../constants'

const iamRouter: RouteRecordRaw = {
  path: '/iam',
  name: 'iam',
  component: DefaultLayout,
  redirect: '/iam/permission',
  meta: {
    title: $t('iam.name'),
    icon: () => Setting,
  },
  children: [
    {
      path: 'user',
      name: 'IamUser',
      meta: {
        title: $t('iam.user.name'),
      },
      component: () => import('@/views/iam/user/index.vue'),
    },
    {
      path: 'permission',
      name: 'IamPermission',
      meta: {
        title: $t('iam.permission.name'),
      },
      component: () => import('@/views/iam/permission/index.vue'),
    },
    {
      path: 'organization',
      name: 'IamOrganization',
      meta: {
        title: $t('iam.org.name'),
      },
      component: () => import('@/views/iam/organization/index.vue'),
    },
    {
      path: 'role',
      name: 'IamRole',
      meta: {
        title: $t('iam.role.name'),
      },
      component: () => import('@/views/iam/role/index.vue'),
    },
    {
      path: 'log',
      name: 'IamLogs',
      meta: {
        title: $t('iam.log.name'),
      },
      component: () => import('@/views/iam/logs/index.vue'),
    },
  ],
}

export default iamRouter
