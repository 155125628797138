import { get } from 'lodash-es'

export const useEnumsStore = defineStore('enum-store', () => {
  const enumGroups = ref<Recordable<Readonly<UniversalOption[]>>>({})

  const setGroup = (key: string, group: UniversalOption[]) => {
    if (enumGroups.value[key])
      throw new Error(`key: ${key}已经注册，请确认是否重复或重新命名key`)

    if (!group.every(v => Object.hasOwn(v, 'value') && Object.hasOwn(v, 'label')))
      throw new Error(`key: ${key}中包含不符合条件的项，请保证每一项都包含value和label项`)

    enumGroups.value[key] = group
  }

  // 根据索引获取整个枚举组
  const getEnumGroup = (key: EnumGroupKey) => {
    const group = get(enumGroups.value, key)
    if (!group) {
      console.error(`请先使用registerEnums注册${key}后再调用本地枚举渲染`)
      return []
    }

    return group as unknown as UniversalOption[]
  }

  // 根据索引和值获取枚举对象
  const getEnumItem = (key: EnumGroupKey, value: GetPropType<UniversalOption, 'value'>) => {
    return getEnumGroup(key).find(v => v.value === value) as UniversalOption
  }

  // 根据索引和值获取枚举渲染器
  const getEnumItemRender = (key: EnumGroupKey, value: GetPropType<UniversalOption, 'value'>) => {
    const item = getEnumItem(key, value)
    if (!item)
      return <span>{value}</span>

    return item.render ? item.render : () => <span class={item.class || ''}>{item.label}</span>
  }

  // 根据索引和值获取枚举的label
  const getEnumItemLabel = (key: EnumGroupKey, value: GetPropType<UniversalOption, 'value'>) => {
    return getEnumItem(key, value).label
  }

  return {
    enumGroups,
    setGroup,
    getEnumGroup,
    getEnumItem,
    getEnumItemRender,
    getEnumItemLabel,
  }
})
