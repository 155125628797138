import { getSysLang } from './store'

export * from './source'
export * from './types'
export * from './store'

const lang = localStorage.getItem('lang') ?? 'zh'
const langCache = lang ? JSON.parse(localStorage.getItem(`lang-cache-${lang}`) ?? '{}') : undefined

export const i18n = createI18n({
  locale:
    (import.meta.env.VITE_APP_NAME === 'client' ? getSysLang() : lang),
  messages: {
    [lang]: langCache,
  },
  legacy: false,
  globalInjection: true, // 全局生效$t
})

export interface I18nGlobalTranslation {
  (key: string): string
  (key: string, locale: string): string
  (key: string, locale: string, list: unknown[]): string
  (key: string, locale: string, named: Record<string, unknown>): string
  (key: string, list: unknown[]): string
  (key: string, named: Record<string, unknown>): string
}

/**
 * 根据key翻译至设置的语言
 * @param {string} key 预设的key
 */
// @ts-expect-error 实际使用没有问题
export const $t = i18n.global.t.bind(i18n.global) as I18nGlobalTranslation
