import type { FormExpose } from 'nx-components/form'

import type { FormContainerInjection } from './types'

// 收集子组件中的nx-form的expose为数组
export function useFormInstanceGather(): FormContainerInjection {
  const formInstances = ref<FormExpose[]>([])
  // 添加表单实例
  const addFormInstance = (formInstance: FormExpose) => {
    formInstances.value.push(formInstance)
  }
  // 去除表单实例
  const removeFormInstance = (formInstance: FormExpose) => {
    formInstances.value.splice(formInstances.value.indexOf(formInstance), 1)
  }

  provide('form-container', {
    addFormInstance,
    removeFormInstance,
  })
  // 去除所有的校验
  const clearValidate = () => {
    formInstances.value.forEach(v => v.clearValidate())
  }

  return {
    formInstances,
    addFormInstance,
    removeFormInstance,
    clearValidate,
  }
}
