import chalk from 'chalk'

import type { UploadFn } from './types'

/**
 * 为上传组件注册上传文件的方法
 * @param uploadFn
 */
export function registerUploaderFn(uploadFn: UploadFn) {
  window.uploadFn = uploadFn
  console.log(chalk.green('上传组件上传方法注册成功！'))
}

declare global {
  interface Window {
    uploadFn?: UploadFn
  }
}
