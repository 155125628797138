import type { DirectiveBinding, Ref } from 'vue'
import type { RouteRecordRaw, RouteRecordRawWithMeta, Router } from 'vue-router'

import { Api_V1WebHomeUserMyInfo } from '@/api/__auto__/base-inc-center/V1'
import { asyncRoutes } from '@/router/routes'
import { useUserInfoStore } from '@/stores/user-info'

export function getAccountAccessDetailFn() {
  return Api_V1WebHomeUserMyInfo({ hasLoading: false }).then((resp) => {
    const { setUserInfo } = useUserInfoStore()
    setUserInfo(resp)
    return resp
  })
}

const whitePathList = ['/login', '/empty-home', '/', '/404', ...(import.meta.hot ? examplePaths : [])]
export const useAccessStore = defineStore(
  'access',
  (): {
    isDynamicAddedRoutes: Ref<boolean>
    generateRoutes: (router: Router) => Promise<RouteRecordRaw[]>
    accessMenus: Ref<RouteRecordRawWithMeta[]>
    accesses: Ref<string[]>
    hasAccess: (code: string) => boolean
  } => {
    const isDynamicAddedRoutes = ref(false)
    // 权限key列表
    const accesses = ref<string[]>([])
    const accessMenus = ref<RouteRecordRawWithMeta[]>([])
    // 根据接口返回的权限表动态生成路由
    const generateRoutes = async (router: Router): Promise<RouteRecordRaw[]> => {
      isDynamicAddedRoutes.value = true
      const currentRoutes = router.getRoutes()
      currentRoutes.forEach((v) => {
        if (!whitePathList.includes(v.path) && v.name)
          router.removeRoute(v.name)
      })
      const { menuApis, buttonApis } = await getAccountAccessDetailFn()
      accesses.value = [...new Set([...whitePathList, ...menuApis, ...buttonApis])]
      const routes = filterAsyncRoutes(asyncRoutes, accesses.value)
      // 如果是本地开发则展示模板页面菜单和添加路由
      if (import.meta.env.hot)
        routes.push(...exampleRoutes)

      routes.forEach(v => router.addRoute(v))
      accessMenus.value = removeRouteComponent(routes) as RouteRecordRawWithMeta[]

      return routes
    }
    // 是否有该权限
    const hasAccess = (code: string) => accesses.value.includes(code)

    return { isDynamicAddedRoutes, generateRoutes, accessMenus, accesses, hasAccess }
  },
)

// 递归过滤出有权限的路由
function filterAsyncRoutes(routes: RouteRecordRaw[], accesses: string[]) {
  const res: RouteRecordRaw[] = []
  routes.forEach((route) => {
    const temp = cloneDeep(route)
    if (
      temp?.meta?.fullPath
      && accesses.includes(temp.meta.fullPath)
      && temp?.meta?.singleton !== false // 如果是单例则由tab动态添加路由
    ) {
      if (temp.children)
        temp.children = filterAsyncRoutes(temp.children, accesses)

      res.push(temp)
    }
  })
  return res
}

export type RouteRecordRawWithoutComponent = Omit<RouteRecordRaw, 'component'> & {
  children: RouteRecordRawWithoutComponent[]
}

// 递归将路由配置中的component去掉,用于菜单渲染
function removeRouteComponent(routes: RouteRecordRaw[]): RouteRecordRawWithoutComponent[] {
  const res: RouteRecordRawWithoutComponent[] = []
  routes.forEach((route) => {
    const temp = cloneDeep(route)
    delete temp.component
    if (temp.children)
      temp.children = removeRouteComponent(temp.children) as RouteRecordRaw[]

    res.push(temp as RouteRecordRawWithoutComponent)
  })
  return res
}

// 校验是否有权限的自定义指令，全局注册
export const vAccess = {
  mounted(el: HTMLElement, binding: DirectiveBinding<string>) {
    const { hasAccess } = useAccessStore()
    const code = binding.value || ''
    // 要是没有该权限则将其remove掉
    if (!hasAccess(code)) {
      if (!el.parentNode)
        el.style.display = 'none'
      else
        el.parentNode.removeChild(el)
    }
  },
}

// 判断是否有权限
export const hasAccess = (code: string) => useAccessStore().hasAccess(code)
