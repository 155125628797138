import type { RemoteSelectConfig } from 'nx-components/remote-select'

export const remoteSelectConfigs: Recordable<RemoteSelectConfig> = {}

/**
 * 注册远程下拉的请求配置
 * @param {RemoteSelectConfig} options
 */
export function registerRemoteSelect(options: RemoteSelectConfig) {
  const { label } = options
  if (remoteSelectConfigs[label])
    throw new Error(`远程搜索label：${label}已被注册，请查看可否复用`)

  remoteSelectConfigs[label] = options
  console.log(chalk.green(`远程搜索下拉[${options.label}]注册成功！`))
}

/**
 * 批量注册远程下拉请求配置
 * @param {RemoteSelectConfig[]} configs
 */
export function batchRegisterRemoteSelect(configs: RemoteSelectConfig[]) {
  return configs.forEach(v => registerRemoteSelect(v))
}

/**
 * 根据label获取配置
 * @param {string} label
 */
export function getConfigItem(label: string) {
  return remoteSelectConfigs[label]
}
