/**
 * @title 存储桶*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {}
/**
 * No description
 * @tags 存储桶
 * @name GetTempBucketUploadSigned
 * @summary 临时文件上传预授权URL
 * @request POST:/interior/bucket/getTempBucketUploadSigned
 */
export function Api_InteriorBucketGetTempBucketUploadSigned(
  data: BaseSaasCloudBucketSignReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseSaasCloudGetTempBucketUploadSigned["data"]> {
  return request_({
    url: `/base-saas-cloud/interior/bucket/getTempBucketUploadSigned`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 存储桶
 * @name GetOtaUploadSigned
 * @summary OTA文件上传预授权URL
 * @request POST:/interior/bucket/getOtaUploadSigned
 */
export function Api_InteriorBucketGetOtaUploadSigned(
  data: BaseSaasCloudBucketSignReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseSaasCloudGetOtaUploadSigned["data"]> {
  return request_({
    url: `/base-saas-cloud/interior/bucket/getOtaUploadSigned`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 存储桶
 * @name GetFileTempUrl
 * @summary 私有文件临时访问预授权URL
 * @request POST:/interior/bucket/getFileTempUrl
 */
export function Api_InteriorBucketGetFileTempUrl(
  data: BaseSaasCloudBucketSignReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseSaasCloudGetFileTempUrl["data"]> {
  return request_({
    url: `/base-saas-cloud/interior/bucket/getFileTempUrl`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 存储桶
 * @name GetExcelUploadSigned
 * @summary 获取Excel上传预授权URL
 * @request POST:/interior/bucket/getExcelUploadSigned
 */
export function Api_InteriorBucketGetExcelUploadSigned(
  data: BaseSaasCloudBucketSignReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseSaasCloudGetExcelUploadSigned["data"]> {
  return request_({
    url: `/base-saas-cloud/interior/bucket/getExcelUploadSigned`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 存储桶
 * @name GetCommonFileUploadSigned
 * @summary 通用公共文件上传预授权URL
 * @request POST:/interior/bucket/getCommonFileUploadSigned
 */
export function Api_InteriorBucketGetCommonFileUploadSigned(
  data: BaseSaasCloudBucketSignReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseSaasCloudGetCommonFileUploadSigned["data"]> {
  return request_({
    url: `/base-saas-cloud/interior/bucket/getCommonFileUploadSigned`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 存储桶
 * @name GetCertificateUploadSigned
 * @summary 证件凭证文件上传预授权URL（私有桶）
 * @request POST:/interior/bucket/getCertificateUploadSigned
 */
export function Api_InteriorBucketGetCertificateUploadSigned(
  data: BaseSaasCloudBucketSignReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseSaasCloudGetCertificateUploadSigned["data"]> {
  return request_({
    url: `/base-saas-cloud/interior/bucket/getCertificateUploadSigned`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 存储桶
 * @name GetBatchTempBucketUploadSigned
 * @summary 批量临时文件上传预授权URL
 * @request POST:/interior/bucket/getBatchTempBucketUploadSigned
 */
export function Api_InteriorBucketGetBatchTempBucketUploadSigned(
  data: BaseSaasCloudBucketBatchSignReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseSaasCloudGetBatchTempBucketUploadSigned["data"]> {
  return request_({
    url: `/base-saas-cloud/interior/bucket/getBatchTempBucketUploadSigned`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 存储桶
 * @name GetBatchCommonFileUploadSigned
 * @summary 批量通用公共文件上传预授权URL
 * @request POST:/interior/bucket/getBatchCommonFileUploadSigned
 */
export function Api_InteriorBucketGetBatchCommonFileUploadSigned(
  data: BaseSaasCloudBucketBatchSignReq,
  extraConfig: ExtraConfig = {},
): Promise<BaseSaasCloudGetBatchCommonFileUploadSigned["data"]> {
  return request_({
    url: `/base-saas-cloud/interior/bucket/getBatchCommonFileUploadSigned`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}
