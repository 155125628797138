<script lang="ts" setup>
const i18nStore = useI18nStore()
const { localeData } = storeToRefs(i18nStore)
provide(referenceRoutesKey, ref([]))
</script>

<template>
  <el-config-provider :locale="localeData.el">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </el-config-provider>
</template>

<style lang="scss"></style>
