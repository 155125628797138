import type { RouteRecordRaw } from 'vue-router'

// 给route配置meta添加fullPath属性
export function assignFullPath(routes: RouteRecordRaw[], parentPath: string): RouteRecordRaw[] {
  routes = cloneDeep(routes)
  routes.forEach((route) => {
    route.meta!.fullPath = `${parentPath}${route.path.startsWith('/') ? '' : '/'}${route.path}`
    if (route?.children?.length)
      route.children = assignFullPath(route.children, route.meta!.fullPath)
  })
  return routes
}

/**
 * 创建路由页面组件
 * @param name 组件名称
 * @param PageComponent 页面组件
 */
export function createPageRouteComponent(name: string, PageComponent: RouteRecordRaw['component']) {
  const AsyncPageComponent = defineAsyncComponent(PageComponent)

  return defineComponent({
    name,
    setup(props, { attrs }) {
      return () => <AsyncPageComponent {...attrs} />
    },
  })
}
