import type { AxiosRequestConfig, Canceler } from 'axios'

function getPendingKey(requestConfig: AxiosRequestConfig): string {
  return [
    requestConfig.method,
    requestConfig.url,
    requestConfig.params?.toString(),
    requestConfig.data?.toString(),
  ].join('&')
}

export const useRequestCanceler = defineStore('requestCanceler', () => {
  const pendingMap = reactive(new Map<string, Canceler>())
  const removePending = (requestConfig: AxiosRequestConfig) => {
    const key = getPendingKey(requestConfig)
    if (pendingMap.has(key)) {
      const canceler = pendingMap.get(key) as Canceler
      canceler()
      pendingMap.delete(key)
    }
  }
  const addPending = (
    _requestConfig: AxiosRequestConfig & { cancelable?: boolean },
    _canceler: Canceler,
  ) => {
    // TODO 暂时注释掉
    // if (_requestConfig.cancelable === false) {
    //   return;
    // }
    // removePending(_requestConfig);
    // const key = getPendingKey(_requestConfig);
    // if (!pendingMap.has(key)) {
    //   pendingMap.set(key, _canceler);
    // }
  }

  const removeAllPending = () => {
    pendingMap.forEach(canceler => canceler())
    pendingMap.clear()
  }

  return { pendingMap, removePending, addPending, removeAllPending }
})
