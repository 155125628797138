import type { RouteRecordRaw } from 'vue-router'

export const emptyHomePath = '/empty-home'
export const emptyHomeName = 'empty-home'

// 获取不需要权限的公共页面
export function getCommonViews(): RouteRecordRaw[] {
  return assignFullPath(
    [
      {
        path: emptyHomePath,
        name: emptyHomeName,
        component: () => import('./empty-home.vue'),
        meta: {
          title: $t('commonWords.homePage'),
          hidden: true,
          hideBreadCrumb: true,
        },
      },
      {
        path: '/404',
        component: () => import('./404.vue'),
        meta: {
          title: '404',
          hidden: true,
          hideBreadCrumb: true,
        },
      },
    ],
    '',
  )
}
