import { Setting } from '@element-plus/icons-vue'
import type { RouteRecordRaw } from 'vue-router'

import { DefaultLayout } from '../../constants'

const messageCenterRouter: RouteRecordRaw = {
  path: '/message-center',
  name: 'MessageCenter',
  component: DefaultLayout,
  redirect: '/message-center/notice-message',
  meta: {
    title: $t('messageCenter.name'),
    icon: () => Setting,
  },
  children: [
    {
      path: 'notice-message',
      name: 'MessageCenterNoticeMessage',
      meta: {
        title: $t('messageCenter.noticeMessage.name'),
      },
      component: () => import('@/views/message-center/notice-message/index.vue'),
    },
    {
      path: 'create-notice-message',
      name: 'MessageCenterCreateNoticeMessage',
      meta: {
        title: $t('messageCenter.noticeMessage.create'),
        hidden: true,
        singleton: false,
        parentRoutePath: '/message-center/notice-message',
        parentTitle: $t('messageCenter.noticeMessage.name'),
      },
      component: () => import('@/views/message-center/notice-message/create-notice-message.vue'),
    },
    {
      path: 'user-message',
      name: 'MessageCenterUserMessage',
      meta: {
        title: $t('messageCenter.userMessage.name'),
      },
      component: () => import('@/views/message-center/user-message/index.vue'),
    },
    {
      path: 'notice-config',
      name: 'MessageCenterNoticeConfig',
      meta: {
        title: $t('messageCenter.noticeConfig.name'),
      },
      component: () => import('@/views/message-center/notice-config/index.vue'),
    },
  ],
}

export default messageCenterRouter
