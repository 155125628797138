import type { Ref } from 'vue'

export interface UserReqQueueExposes {
  list: Ref<Set<string>>
  add: (uri: string) => void
  remove: (uri: string) => void
  size: () => number
}

export const useReqQueueStore = defineStore('req-queue', (): UserReqQueueExposes => {
  const list = ref<Set<string>>(new Set())

  const add = (uri: string) => {
    list.value.add(uri)
  }

  const remove = (uri: string) => {
    list.value.delete(uri)
  }

  const size = () => list.value.size

  return { list, add, remove, size }
})
