import { emptyHomeName, emptyHomePath } from 'nx-layouts/common-views'
import type { Router } from 'vue-router'

import type { LayoutTab } from './types'

export const useLayoutTabsStore = defineStore('layout-tabs-store', () => {
  const layoutTabs = ref<LayoutTab[]>([
    {
      title: $t('commonWords.homePage'),
      active: true,
      originName: emptyHomeName,
      routeName: emptyHomeName,
      fullPath: emptyHomePath,
      closeable: false,
      draggable: false,
      contextDisable: true,
      query: {},
      params: {},
    },
  ])
  const historyStack = ref<string[]>([])
  const originNameCount = ref<Recordable<number>>({})
  const tabLoading = ref(true)

  const addTab = (newTab: LayoutTab) => {
    layoutTabs.value.forEach(v => (v.active = false))
    let _tabIndex = layoutTabs.value.findIndex(
      v =>
        newTab.singleton === false
          ? v.fullPath === newTab.fullPath // 非单例模式以全路径为匹配依据
          : v.routeName === newTab.routeName, // 单例模式以路由名为匹配依据
    )
    if (_tabIndex === -1) {
      if (newTab.originName) {
        originNameCount.value[newTab.originName] = originNameCount.value[newTab.originName] ?? -1
        originNameCount.value[newTab.originName]++
        newTab.title
          = originNameCount.value[newTab.originName] === 0
            ? newTab.title
            : `${newTab.title}(${originNameCount.value[newTab.originName]})`
      }
      layoutTabs.value.push(newTab)
      _tabIndex = layoutTabs.value.length - 1
    }
    historyStack.value.push(newTab.fullPath)
    layoutTabs.value[_tabIndex].active = true
  }
  const activeHome = (router: Router, push = true) => {
    layoutTabs.value.find(v => v.fullPath === emptyHomePath)!.active = true
    return push ? router.push(emptyHomePath) : null
  }
  const removeTab = async (tab: LayoutTab, router: Router) => {
    const index = historyStack.value.findIndex(v => v === tab.fullPath)
    layoutTabs.value = layoutTabs.value.filter(v => v.fullPath !== tab.fullPath)
    historyStack.value = historyStack.value.filter(v => v !== tab.fullPath)
    router.removeRoute(tab.routeName)
    if (!tab.active)
      return

    let latestTab: LayoutTab | undefined

    if (historyStack.value.length) {
      const lastHistoryStack = historyStack.value[index] || historyStack.value[index - 1]
      latestTab = layoutTabs.value.find(v => v.fullPath === lastHistoryStack)
    }

    if (!latestTab)
      return activeHome(router)

    const { fullPath, query, params } = latestTab
    return router.push({ path: fullPath, query, params })
  }

  const removeLeftTab = (tab: LayoutTab, router: Router) => {
    layoutTabs.value.slice(1, layoutTabs.value.indexOf(tab)).forEach(v => removeTab(v, router))
  }
  const removeRightTab = (tab: LayoutTab, router: Router) => {
    layoutTabs.value.slice(layoutTabs.value.indexOf(tab) + 1).forEach(v => removeTab(v, router))
  }
  const removeAllTab = (router: Router) => {
    layoutTabs.value.slice(1).forEach(v => removeTab(v, router))
  }

  const removeCurrentTab = (router: Router) => {
    const currentTab = layoutTabs.value.find(v => v.active)
    if (currentTab && currentTab?.routeName !== emptyHomeName)
      return removeTab(currentTab, router)
  }

  return {
    layoutTabs,
    historyStack,
    originNameCount,
    addTab,
    removeTab,
    activeHome,
    removeLeftTab,
    removeRightTab,
    removeAllTab,
    removeCurrentTab,
    tabLoading,
  }
})
