import { createTabsGuard } from 'nx-layouts/components/layout-tabs/src/utils'
import { createLayoutGuard } from 'nx-layouts/utils'
import type { Router } from 'vue-router'

import { createAccessGuard } from './access-guard'
import { createDistUpdateNotifyGuard } from './dist-update-notify-guard'

export function createGuard(router: Router) {
  createAccessGuard(router)
  createTabsGuard(router)
  createLayoutGuard(router)
  createDistUpdateNotifyGuard(router)
}
