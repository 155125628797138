import type { Router } from 'vue-router'

import { useAccessStore } from '@/utils/access'

export function createAccessGuard(router: Router) {
  router.beforeEach(async (to) => {
    if (to.name === 'login')
      return

    const accessStore = useAccessStore()
    const { generateRoutes } = accessStore
    // 路由是否已动态添加;
    const { isDynamicAddedRoutes } = storeToRefs(accessStore)
    // 未动态添加路由时，先动态添加路由
    if (!isDynamicAddedRoutes.value) {
      document.querySelector('#page-loading')?.classList.add('visible')
      const routes = await generateRoutes(router)
      // 有首页权限默认进入首页，没有首页权限默认进入主页
      const home = routes.find(item => item.name === 'DataHome')
      const { query, params } = to
      return home ? { path: home.path } : { path: to.fullPath, query, params }
    }
  })
  router.afterEach(() => {
    // 路由是否已动态添加;
    const { isDynamicAddedRoutes } = storeToRefs(useAccessStore())
    if (isDynamicAddedRoutes.value)
      document.querySelector('#page-loading')?.classList.remove('visible')
  })
}
