import axios from 'axios'
import type {
  AxiosInstance,
  AxiosInterceptorOptions,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  InternalAxiosRequestConfig,
} from 'axios'

interface InterceptorParams<V> {
  onFulfilled?: (value: V) => V | Promise<V>
  onRejected?: (error: any) => void
  options?: AxiosInterceptorOptions
}

export interface RequestConfig<T extends object = Record<string, unknown>> {
  baseURL: string
  requestInterceptor: InterceptorParams<InternalAxiosRequestConfig & T>
  responseInterceptor: InterceptorParams<
    AxiosResponse & {
      config: T
    }
  >
}

export class RequestFactory<X extends object = Record<string, unknown>> {
  public instance: AxiosInstance

  constructor(config: RequestConfig<X>) {
    this.instance = axios.create({
      baseURL: config.baseURL,
      timeout: 30000,
    })
    this.instance.interceptors.request.use(
      config.requestInterceptor.onFulfilled as unknown as (
        value: InternalAxiosRequestConfig,
      ) => (InternalAxiosRequestConfig & RequestHeaders) | Promise<InternalAxiosRequestConfig>,
      config.requestInterceptor.onRejected,
      config.requestInterceptor.options,
    )
    this.instance.interceptors.response.use(
      config.responseInterceptor.onFulfilled as unknown as (
        v: AxiosResponse,
      ) => AxiosResponse | Promise<AxiosResponse>,
      config.responseInterceptor.onRejected,
      config.responseInterceptor.options,
    )
  }

  request<T = never, R = AxiosResponse<T>, D = never>(
    config: SetRequired<AxiosRequestConfig<D>, 'url' | 'method'> & X,
  ) {
    const { removePending, addPending } = useRequestCanceler()
    const canceler = ref<Canceler>()
    const request = this.instance
      .request({
        ...config,
        cancelToken: new axios.CancelToken((c) => {
          canceler.value = c
          addPending(config, c)
        }),
      })
      .then((resp) => {
        removePending(config)
        return resp
      })
    request.canceler = canceler
    return request as Promise<R>
  }
}

export default RequestFactory
