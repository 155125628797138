import type { ComputedRef, Ref } from 'vue'

import { unflatten } from 'flat'
import { getLocaleList, getOnlineLocales, localeMap } from './source'

// 获取当前时区与UTC标准时区的时差
// function getCurrentUTCDifference() {
//   const date = new Date()
//   return -date.getTimezoneOffset() / 60
// }

// 获取UA中设置的系统语言 移动端使用 降级使用url中的lang storage中的locale
export function getSysLang(): string {
  const matchData = navigator.userAgent.match(/(Lang);?[\s/]+([a-z\-.]+)?/)
  if (matchData)
    return matchData[2]

  const urlObj = new URL(window.location.href)
  if (urlObj.searchParams.has('lang'))
    return urlObj.searchParams.get('lang')!

  if (localStorage.getItem('lang'))
    return localStorage.getItem('lang')!

  return 'zh'
}

export const useI18nStore = defineStore(
  'i18n',
  (): {
    locale: Ref<string>
    locales: Ref<Recordable<Record<string, string>>>
    UTC: string
    localeData: ComputedRef<Record<string, string>>
    changeLocale: (target: string) => void
  } => {
    const i18n = useI18n()
    const locale
      = import.meta.env.VITE_APP_NAME === 'client'
        ? ref<string>(getSysLang())
        : useStorage<string>('lang', 'zh', localStorage) // 当前选择的地区语言
    const locales = ref<Recordable<Record<string, string>>>({})
    // UTC 时区显示关闭
    // const UTCDifference = getCurrentUTCDifference()
    // const UTC = `UTC ${UTCDifference >= 0 ? '+' : ''}${UTCDifference}`
    const UTC = ''
    i18n.locale.value = locale.value
    localeMap[locale.value as keyof typeof localeMap].dayjs()
    // 刷新当前语言缓存
    getOnlineLocales(locale.value).then((messages) => {
      messages = unflatten(messages)
      i18n.setLocaleMessage(locale.value, messages)
      locales.value[locale.value] = {
        ...(locales.value[locale.value] ?? {}),
        ...messages,
      }
      setLocaleCache(locale.value, messages)
    })
    // 加载可选语言列表
    getLocaleList()
      .then((list) => {
        list.forEach((item) => {
          locales.value[item.lang] = locales.value[item.lang] || {
            name: item.name,
          }
        })
        return list
      })
      // 趁系统空闲提前静默加载所有语言包
      .then((list) => {
        return Promise.allSettled(list.map(async (item) => {
          const lang = item.lang
          const messages: Recordable = unflatten(await getOnlineLocales(lang))
          locales.value[lang] = {
            ...(locales.value[lang] ?? {}),
            ...messages,
          }
          setLocaleCache(lang, messages, true)
        }))
      }).then(() => {
        // 如果是本地开发环境，告诉本地开发服务器更新json
        if (import.meta.hot) {
          const val = cloneDeep(locales.value)
          Object.values(val).forEach((item) => {
            delete item.el
          })
          import.meta.hot.send('nx:i18n-update', val)
        }
      })

    const changeLocale = async (target: string) => {
      locales.value[target] = await getOnlineLocales(target)
      locale.value = target
      i18n.locale.value = target
      setLocaleCache(target, locales.value[target])
      window.location.reload()
    }
    const localeData = computed(() => locales.value[locale.value] || {})

    return {
      locale,
      UTC,
      locales,
      localeData,
      changeLocale,
    }
  },
)

// 设置本地缓存，优化下一次切换语言首次加载时的体验
export function setLocaleCache(lang: string, messages: Recordable<string>, silent = false) {
  const cache = localStorage.getItem(`lang-cache-${lang}`)
  localStorage.setItem(`lang-cache-${lang}`, JSON.stringify(messages))
  if (!cache && !silent)
    window.location.reload()
}
