import type { Ref } from 'vue'

import { Api_UsersSignOut } from '@/api/__auto__/base-iam-account-b/Users'
import { Api_V1WebHomeUserMyInfo } from '@/api/__auto__/base-inc-center/V1'
import { jumpToLogin } from '@/router/utils'

export interface UserInfoStoreExposes {
  // 用户信息
  userInfo: Ref<SysAccountDetailVo | undefined>
  // 设置用户信息
  setUserInfo: (data: SysAccountDetailVo) => void
  // 刷新用户信息
  refreshUserInfo: () => Promise<SysAccountDetailVo>
  // 设置token
  setToken: (token: string) => Promise<SysAccountDetailVo>
  // 获取储存的token
  token: Ref<string>
  // 清空token
  clearToken: () => void
  // 退出登录，会请求登出接口
  signOut: () => Promise<void>
}

export const useUserInfoStore = defineStore('user-info', (): UserInfoStoreExposes => {
  const userInfo = ref<SysAccountDetailVo>()
  const token = useLocalStorage<string>('token', '')
  // 刷新用户信息
  const refreshUserInfo = async () => {
    userInfo.value = await Api_V1WebHomeUserMyInfo()
    return userInfo.value
  }
  const setToken = (_token: string) => {
    token.value = _token
    return refreshUserInfo()
  }
  const clearToken = () => (token.value = '')

  const setUserInfo = (data: SysAccountDetailVo) => {
    userInfo.value = data
  }
  // 退出登录，会请求登出接口
  const signOut = async () => {
    userInfo.value = undefined
    await Api_UsersSignOut()
    jumpToLogin(false)
  }
  return { setToken, clearToken, token, userInfo, setUserInfo, refreshUserInfo, signOut }
})
