export const useCurrentPosition = defineStore(
  'location-store',
  () => {
    const currentPosition = ref<google.maps.LatLngLiteral>()

    const refreshCurrentPosition = () => {
      return new Promise<google.maps.LatLngLiteral>((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((_position) => {
            currentPosition.value = {
              lat: _position.coords.latitude,
              lng: _position.coords.longitude,
            }
            resolve(currentPosition.value)
          }, reject)
        }
        else if (isCnBuild()) {
          AMap.plugin('AMap.Geolocation', () => {
            const geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, // 是否使用高精度定位，默认:true
              timeout: 5000, // 超过10秒后停止定位，默认：无穷大
            })
            geolocation.getCurrentPosition((status, result) => {
              // 获取用户当前的精确位置
              if (status === 'complete') {
                currentPosition.value = result.position
                resolve(result.position)
              }
            })
          })
        }
        else {
          reject($t('components.map.permissionDeniedWarning'))
        }
      })
    }
    refreshCurrentPosition()

    return { currentPosition, refreshCurrentPosition }
  },
)
