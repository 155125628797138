import type { UploadFn } from 'nx-components/upload'

function getRegisterTrimPlugin() {
  let trimPluginRegistered = false
  return (Boot: any) => {
    if (!trimPluginRegistered) {
      Boot.registerPlugin((editor: any) => {
        const { getHtml } = editor
        editor.getHtml = () =>
          !editor.getText().trim() // 若只是输入了标签，没有实际文字的话则不算有输入，方便外部校验空
          && !getHtml().includes('img') // 如果是只有图片的话内容的innerText依然是空，所以排除这种情况
            ? ''
            : getHtml()
        return editor
      })
      trimPluginRegistered = true
    }
  }
}

export const registerTrimPlugin = getRegisterTrimPlugin()

/**
 * 为富文本编辑组件注册上传文件的方法
 * @param uploadFn
 */
export function registerRichTextEditorUpload(uploadFn: UploadFn) {
  window.richTextEditorUpload = uploadFn
  console.log(chalk.green('富文本编辑组件上传方法注册成功！'))
}

declare global {
  interface Window {
    richTextEditorUpload?: UploadFn
  }
}
