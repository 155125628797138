const isCn = isCnBuild()
export class UniPolyline {
  private gmpInstance?: google.maps.Polyline
  private aMapInstance?: AMap.Polyline
  constructor(props: {
    path: google.maps.LatLngLiteral[]
    icon?: {
      start?: google.maps.Icon
      end?: google.maps.Icon
    }
    map?: google.maps.Map | AMap.Map
  }, options?: {
    focus?: boolean
  }) {
    if (isCn) {
      this.aMapInstance = new AMap.Polyline()
    }
    else {
      this.gmpInstance = new google.maps.Polyline({
        path: props.path,
        geodesic: true,
        strokeColor: '#165DFF',
        strokeOpacity: 1.0,
        strokeWeight: 8,
      })
      if (props.map)
        this.setMap(props.map)
      if (props.map && options?.focus) {
        const _bounds = new google.maps.LatLngBounds()
        props.path.forEach(v => _bounds.extend(v))
        ;(props.map as google.maps.Map).fitBounds(_bounds)
      }
    }
  }

  setMap(map: google.maps.Map | AMap.Map | null) {
    if (isCn)
      console.log(isCn)

    else
      this.gmpInstance!.setMap(map as google.maps.Map)
  }

  destroy() {
    if (isCn) {
      console.log(isCn)
    }
    else {
      this.gmpInstance?.setMap(null)
      this.gmpInstance = undefined
    }
  }
}
