/**
 * @title 字典数据详情接口*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {
  export type SysDictDataSelectListGroupByDicCodeParams = Parameters<
    typeof Api_SysDictDataSelectListGroupByDicCode
  >["query"];
}
/**
 * No description
 * @tags 字典数据详情接口
 * @name SelectListGroupByDicCode
 * @summary 查询列表-传入语言lang与dicCodeList不可以为空
 * @request GET:/sysDictData/selectListGroupByDicCode
 */
export function Api_SysDictDataSelectListGroupByDicCode(
  query: BaseIncCenterSelectListGroupByDicCodeParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectListGroupByDicCode["data"]> {
  return request_({
    url: `/base-inc-center/sysDictData/selectListGroupByDicCode`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type SysDictDataSelectDetailByIdParams = Parameters<typeof Api_SysDictDataSelectDetailById>["query"];
}
/**
 * No description
 * @tags 字典数据详情接口
 * @name SelectDetailById
 * @summary 查询详情信息
 * @request GET:/sysDictData/selectDetailById
 */
export function Api_SysDictDataSelectDetailById(
  query: BaseIncCenterSelectDetailByIdParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectDetailById["data"]> {
  return request_({
    url: `/base-inc-center/sysDictData/selectDetailById`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type SysDictDataGetLabelMapByDicCodeParams = Parameters<typeof Api_SysDictDataGetLabelMapByDicCode>["query"];
}
/**
 * No description
 * @tags 字典数据详情接口
 * @name GetLabelMapByDicCode
 * @summary 根据code查询labelMap key: lang-value , value: label
 * @request GET:/sysDictData/getLabelMapByDicCode
 */
export function Api_SysDictDataGetLabelMapByDicCode(
  query: BaseIncCenterGetLabelMapByDicCodeParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterGetLabelMapByDicCode["data"]> {
  return request_({
    url: `/base-inc-center/sysDictData/getLabelMapByDicCode`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}
