import { Api_InteriorBucketGetOtaUploadSigned, Api_InteriorBucketGetTempBucketUploadSigned } from '@/api/__auto__/base-saas-cloud/Interior'
import type { ExtraConfig } from '@/utils/request'

// 不同的上传位置
const obsArr: Recordable<
  (option: BucketSignReq, extraConfig?: ExtraConfig) => Promise<TempSignatureVo>
> = {
  tempBucket: Api_InteriorBucketGetTempBucketUploadSigned, // 临时文件上传
  otaBucket: Api_InteriorBucketGetOtaUploadSigned, // 临时文件上传
}

interface Params {
  obsType?: string // 上传的目的地址
  file: File
  objectKey: string
  renameKey: boolean // 是否需要重命名成哈希值名称
}

export interface UploadResponse {
  /** 上传完成后文件访问URL（回显用） */
  accessURL: string
  /** 请求头信息 */
  actualSignedRequestHeaders: {
    'Content-Type': string
    'Host': string
    'x-obs-acl': string
  }
  /** 原始objectKey */
  objectKey: string
  /** 上传文件url（上传用） */
  url: string
}

export interface UploadOption {
  hashName: boolean // 是否上传时获取文件hash值重命名文件，防止对象储存中的重复，默认为true
  destination?: string // 要上传的地址的分类，传给后端获取对应分类的上传地址
}

// 获取OBS临时URL
export function getObsUploadConfig({ obsType = 'tempBucket', file, objectKey, renameKey }: Params) {
  const fileType = renameKey ? file.name?.substring(file.name?.lastIndexOf('.')) : '' // 文件后缀名
  return obsArr[obsType](
    {
      objectKey: objectKey + fileType,
      contentType: file.type,
      renameKey,
    },
    { hasLoading: false },
  ) as Promise<UploadResponse>
}

// 上传文件到华为云
export async function uploadObs(params: UploadResponse, file: File) {
  await axios({
    url: params.url,
    method: 'put',
    headers: {
      ...params.actualSignedRequestHeaders,
    },
    withCredentials: false,
    maxRedirects: 0,
    data: file,
  })

  return { accessURL: params.accessURL, objectKey: params.objectKey }
}

// 上传文件
export async function uploadFile(file: File, option: UploadOption) {
  const uploadConfig = await getObsUploadConfig({
    obsType: option.destination,
    file,
    objectKey: file.name,
    renameKey: option.hashName,
  })

  await uploadObs(uploadConfig, file)

  return { ...uploadConfig, url: uploadConfig.accessURL }
}

// 批量上传文件
export function batchUploadFile(files: File[], option: UploadOption) {
  return Promise.allSettled(files.map(file => uploadFile(file, option)))
}
