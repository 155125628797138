import { i18n } from 'nx-i18n'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import '@unocss/reset/normalize.css' // 重置浏览器样式表
import 'virtual:uno.css'
import 'virtual:svg-icons-register' // 引入svg组件依赖
import 'element-plus/dist/index.css'

import { initMapResource, useCurrentPosition } from 'nx-components/map'
import App from './App.vue'
import router from './router'
import { Api_AjCaptchaCheck, Api_AjCaptchaGet } from '@/api/__auto__/base-iam-account-b/AjCaptcha'
import { vAccess } from '@/utils/access'
import { batchUploadFile } from '@/utils/obs'
import { registerViewsEnums } from '@/views/register-enums.tsx'

initMapResource().then(() => useCurrentPosition())
const app = createApp(App)

app.use(createPinia())
app.use(i18n)
app.use(router)
app.directive('access', vAccess)

// 注册滑块验证码请求方法
registerCaptchaRequests({
  get: Api_AjCaptchaGet,
  check: Api_AjCaptchaCheck,
})
// 注册远程下拉
batchRegisterRemoteSelect(remoteSelectorConfig)
// 注册富文本上传
registerRichTextEditorUpload(batchUploadFile)
// 注册上传组件的上传方法
registerUploaderFn(batchUploadFile)
// 注册views下面的枚举
registerViewsEnums()

app.mount('#app')
