import { Setting } from '@element-plus/icons-vue'
import type { RouteRecordRaw } from 'vue-router'

import { DefaultLayout } from '../../constants'

const basicsRouter: RouteRecordRaw = {
  path: '/basics',
  name: 'basics',
  component: DefaultLayout,
  redirect: '/basics/brand',
  meta: {
    title: $t('basics.name'),
    icon: () => Setting,
  },
  children: [
    {
      path: 'brand',
      name: 'BasicsBrand',
      meta: {
        title: $t('basics.brand.name'),
      },
      component: () => import('@/views/basics/brand/index.vue'),
    },
    {
      path: 'vehicle-series',
      name: 'BasicsVehicleSeries',
      meta: {
        title: $t('basics.vehicleSeries.name'),
      },
      component: () => import('@/views/basics/vehicle-series/index.vue'),
    },
    {
      path: 'vehicle-model',
      name: 'BasicsVehicleModel',
      meta: {
        title: $t('basics.vehicleModel.name'),
      },
      component: () => import('@/views/basics/vehicle-model/index.vue'),
    },
    {
      path: 'vehicle-color',
      name: 'BasicsVehicleColor',
      meta: {
        title: $t('basics.vehicleColor.name'),
      },
      component: () => import('@/views/basics/vehicle-color/index.vue'),
    },
    {
      path: 'sim',
      name: 'BasicsSim',
      meta: {
        title: $t('basics.sim.name'),
      },
      component: () => import('@/views/basics/sim/index.vue'),
    },
    {
      path: 't-box',
      name: 'BasicsTBox',
      meta: {
        title: $t('basics.tBox.name'),
      },
      component: () => import('@/views/basics/t-box/index.vue'),
    },
    {
      path: 'failure-code',
      name: 'BasicsFailureCode',
      meta: {
        title: $t('basics.failureCode.name'),
      },
      component: () => import('@/views/basics/failure-code/index.vue'),
    },
    {
      path: 'service-station',
      name: 'BasicsServiceStation',
      meta: {
        title: $t('basics.serviceStation.name'),
      },
      component: () => import('@/views/basics/service-station/index.vue'),
    },
  ],
}

export default basicsRouter
