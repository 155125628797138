import type { RequestConfig } from 'nx-utils/RequestFactory'

import { jumpToLogin } from '@/router/utils'
import { useUserInfoStore } from '@/stores/user-info'
import { useReqQueueStore } from '@/stores/req-queue'

export function errorHandler(data?: ResponseBody<never>) {
  if (!data) {
    return ElMessage.warning({
      duration: 3000,
      message: $t('components.request.error'),
    })
  }
  const { toastWhenFailed = true, code, msg } = data
  if (code === 401) {
    jumpToLogin()
  }
  else if (toastWhenFailed) {
    ElMessage.warning({
      duration: 3000,
      message: msg || $t('components.request.error'),
    })
  }
}

export interface ExtraConfig {
  transformResult?: boolean
  hasLoading?: boolean
  cancelable?: boolean // 是否允许在页面跳转时取消请求
  toastWhenFailed?: boolean // 当失败时是否toast提示
  headers?: Recordable<string> // 额外的请求头
  responseType?: 'blob' | 'json' | 'text' // 响应体类型，默认json
  fileName?: string // 下载的文件名
}

export const interceptors: Omit<RequestConfig<ExtraConfig>, 'baseURL'> = {
  requestInterceptor: {
    onFulfilled(requestConfig) {
      const { add } = useReqQueueStore()
      add(requestConfig.url + JSON.stringify(requestConfig.params || ''))

      if (
        typeof requestConfig.data === 'object'
        && !(requestConfig.data instanceof URLSearchParams || requestConfig.data instanceof FormData) // 且不是表单提交
        && !Array.isArray(requestConfig.data) // 且不是数组
      )
        requestConfig.data = removeUndefinedProp(requestConfig.data)

      const { hasLoading = true, data, params } = requestConfig
      if (hasLoading)
        addLoading()
      if ((data && !Array.isArray(data)) || params) {
        requestConfig[data ? 'data' : 'params']
          = requestConfig.data instanceof URLSearchParams || requestConfig.data instanceof FormData
            ? requestConfig.data // 如果是表单提交则原样
            : Object.assign({}, data || params)
      }
      const { token } = storeToRefs(useUserInfoStore())
      if (!requestConfig.headers.Authorization)
        requestConfig.headers.Authorization = `Bearer ${token?.value}`

      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      if (requestConfig.method?.toLowerCase() === 'get') {
        requestConfig.paramsSerializer = requestConfig.paramsSerializer || {}
        // @ts-expect-error 实际使用没有问题
        requestConfig.paramsSerializer.serialize = function (params: any) {
          return Qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }

      // 设置语言请求头
      requestConfig.headers.Lang = requestConfig.headers.Lang ?? localStorage.getItem('lang') ?? 'zh'

      return requestConfig
    },
  },
  responseInterceptor: {
    onFulfilled(response) {
      const { remove } = useReqQueueStore()
      remove(response.config.url + JSON.stringify(response.config.params || ''))

      const { hasLoading = true } = response.config
      if (hasLoading)
        subtractLoading()

      const {
        transformResult = true,
        toastWhenFailed = true,
        responseType = 'json',
      } = response.config
      if (response.status === 200) {
        if (responseType === 'blob') {
          const fileName = response.config.fileName
            // 取响应头中的文件名字段
            || response.headers['content-disposition']?.match(/filename=([^;]+)/)?.[1]
          if (fileName) {
            const a = document.createElement('a')
            a.href = URL.createObjectURL(response.data)
            a.download = decodeURIComponent(fileName.replace(/"/g, ''))
            a.click()
            return
          }
          return response.data
        }

        if (!transformResult)
          return response.data
        if (response.data?.code === 200)
          return response.data.data
      }
      response.data.toastWhenFailed = toastWhenFailed
      errorHandler(response.data)

      return Promise.reject(response.data)
    },
    onRejected(error) {
      console.log(error)
      if (!error)
        throw error

      const { hasLoading = true } = error.config
      if (hasLoading)
        subtractLoading()

      if (error.code === 'ERR_CANCELED') {
        console.log('请求已取消')
        return
      }
      errorHandler(error.response)
      throw error
    },
  },
}

const defaultRequestInstance = new RequestFactory<ExtraConfig>({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  ...interceptors,
})

export const request = defaultRequestInstance.request.bind(defaultRequestInstance)

export default request
