// 地图构造器资源

let mapConstructor: typeof google | typeof AMap

declare global {
  interface Window {
    _AMapSecurityConfig: {
      serviceHost: string
    }
    AMap: typeof AMap
  }
}
// 初始化并获取地图构造器的Promise对象
export async function initMapResource<T extends(typeof google | typeof AMap)>(): Promise<T> {
  if (mapConstructor)
    return mapConstructor as T

  if (isCnBuild()) {
    const { load } = await import('@amap/amap-jsapi-loader')
    window._AMapSecurityConfig = {
      serviceHost: `${window.location.origin}/_AMapService`,
    }

    const AMap = await load({
      version: '2.0',
      key: import.meta.env.VITE_APP_AMAP_API_KEY,
      plugins: [
        'AMap.MarkerClusterer',
        'AMap.DistrictSearch',
        'AMap.PolyEditor',
        'AMap.Geocoder',
        'AMap.CircleEditor',
        'AMap.AutoComplete',
        'AMap.PlaceSearch',
        'AMap.CitySearch',
        'AMap.Control',
        'AMap.event',
        'AMap.PolygonEditor',
        'AMap.CircleEditor',
      ],
    })
    window.AMap = AMap
    mapConstructor = AMap
    return AMap
  }
  else {
    const { Loader } = await import('@googlemaps/js-api-loader')
    const loader = new Loader({
      apiKey: import.meta.env.VITE_APP_GMP_API_KEY,
      libraries: ['places', 'maps', 'geocoding', 'marker'],
    })
    return new Promise((resolve, reject) => {
      loader.loadCallback((e) => {
        if (e) {
          return reject(e)
        }
        else {
          mapConstructor = window.google
          return resolve(window.google as T)
        }
      })
    })
  }
}

// declare global {
//   interface Window {
//     addressSelectorDialog: any
//   }
// }
// export function openMapDialog(target: google.maps.LatLngLiteral & { address: string }) {
//   if (!window.addressSelectorDialog) {
//     const dialog = createVNode(() => {
//       return (
//         <NxDialog>
//           <div class="mb-2 text-base">
//             <div>
//               {`${$t('components.map.prev')}: ${address.value}` || '/'}
//             </div>
//             <div>
//               {`${$t('components.map.prev')}: ${address.value}` || '/'}
//             </div>
//           </div>
//           <div ref="mapEl" style="height: 50vh;width: 100%" />
//         </NxDialog>
//       )
//     })
//   }
// }
