import { Document, DocumentCopy, Edit, List } from '@element-plus/icons-vue'
import type { RouteRecordRaw } from 'vue-router'

import { DefaultLayout } from '@/router/constants'

// 示例页面路由
export const exampleRoutes: RouteRecordRaw[] = assignFullPath(
  [
    {
      path: '/examples',
      name: 'examples',
      component: DefaultLayout,
      redirect: '/examples/list',
      meta: {
        title: '模板页面',
        icon: () => DocumentCopy,
      },
      children: [
        {
          path: 'list',
          name: 'ExampleList', // 务必和组件defineOptions设置的name一致才能keep-alive
          component: () => import('./list.vue'),
          meta: {
            title: '普通列表页面模板',
            icon: () => List, // 这里一定要用函数返回，直接传组件的话会影响性能
            hideBreadCrumb: false, // 可选，默认为false
            hidden: false, // 可选，默认为false
            keepAlive: true, // 可选，是否在base-layout中keepalive，默认为true，注意组件的name要和route的name一致
          },
        },
        {
          path: 'update',
          name: 'ExampleUpdate',
          component: () => import('./update.vue'),
          meta: {
            title: '普通表单页模板',
            icon: () => Edit,
          },
        },
        {
          path: 'detail',
          name: 'ExampleDetail',
          component: () => import('./detail.vue'),
          meta: {
            title: '普通详情页模板',
            icon: () => Document,
          },
        },
      ],
    },
  ],
  '',
)

// 全量路径，用于配置白名单
export const examplePaths = ['/examples', '/examples/list', '/examples/update', '/examples/detail']
