const isCn = isCnBuild()

export class UniCircle {
  private gmpInstance?: google.maps.Circle
  private aMapInstance?: AMap.Circle
  private map?: any
  private editor?: any
  constructor(props: {
    center: google.maps.LatLngLiteral
    radius: number
    map: google.maps.Map | AMap.Map
  }, options?: {
    focus?: boolean
  }) {
    this.map = props.map
    if (isCn) {
      this.aMapInstance = new AMap.Circle({
        center: [props.center.lng, props.center.lat], // 中心点
        radius: props.radius, // 半径m
        borderWeight: 1,
        storkeColor: '#ff33ff',
        strokeOpacity: 1,
        strokeWeight: 1,
        fillOpacity: 0.4,
        strokeStyle: 'dashed',
        strokeDasharray: [10, 10],
        fillColor: '#1791fc',
        zIndex: 7,
        ...options,
      })
      this.map.add(this.aMapInstance)
      this.map.setFitView([this.aMapInstance])
    }
    else {
      this.gmpInstance = new google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: this.map,
        center: props.center,
        radius: props.radius,
        ...options,
      })
    }
  }

  circleEditor(callback?: () => void) {
    if (isCn) {
      this.editor = new AMap.CircleEditor(this.map, this.aMapInstance)
      this.editor.open()
      this.editor.on('adjust', () => {
        const radius = this.aMapInstance.getRadius() as number
        if (radius > 99999)
          this.aMapInstance.setRadius(99999)
        if (callback)
          callback(radius > 99999 ? 99999 : radius)
      })
    }
    else {
      this.gmpInstance?.setOptions({
        draggable: true,
        editable: true,
      })
      this.gmpInstance?.addListener('radius_changed', () => {
        const radius = this.gmpInstance?.getRadius() as number
        if (radius > 99999)
          this.gmpInstance?.setOptions({ radius: 99999 })
        if (callback)
          callback()
      })
      this.gmpInstance?.addListener('center_changed', () => {
        if (callback)
          callback()
      })
    }
  }

  getCenterRadius() {
    if (isCn) {
      const center = this.aMapInstance.getCenter() as google.maps.LatLngLiteral
      const radius = this.aMapInstance.getRadius() as number
      return { center, radius }
    }
    else {
      const center = this.gmpInstance?.getCenter()?.toJSON() as google.maps.LatLngLiteral
      const radius = this.gmpInstance?.getRadius() as number
      return { center, radius }
    }
  }

  setRadius(radius: number) {
    if (isCn)
      this.aMapInstance.setRadius(radius > 99999 ? 99999 : radius)

    else
      this.gmpInstance?.setOptions({ radius: radius > 99999 ? 99999 : radius })
  }

  destroy() {
    if (isCn) {
      this.editor.close()
      this.map.remove(this.aMapInstance)
    }
    else {
      this.gmpInstance?.setMap(null)
      google.maps.event.clearListeners(this.gmpInstance!, 'radius_changed')
      this.gmpInstance = undefined
    }
  }
}
