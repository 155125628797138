/**
 * @title 角色管理接口*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {}
/**
 * No description
 * @tags 角色管理接口
 * @name Update
 * @summary 修改
 * @request POST:/sysRole/update
 */
export function Api_SysRoleUpdate(
  data: BaseIncCenterSysRoleUpdateVo,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterUpdate["data"]> {
  return request_({
    url: `/base-inc-center/sysRole/update`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 角色管理接口
 * @name UpdateAble
 * @summary 修改启用状态
 * @request POST:/sysRole/updateAble
 */
export function Api_SysRoleUpdateAble(
  data: BaseIncCenterSysRoleUpdateAbleVo,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterUpdateAble["data"]> {
  return request_({
    url: `/base-inc-center/sysRole/updateAble`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 角色管理接口
 * @name BatchDelete
 * @summary 删除
 * @request POST:/sysRole/delete
 */
export function Api_SysRoleDelete(
  data: BaseIncCenterDeleteRequestLong,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterBatchDelete["data"]> {
  return request_({
    url: `/base-inc-center/sysRole/delete`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {}
/**
 * No description
 * @tags 角色管理接口
 * @name Add
 * @summary 添加
 * @request POST:/sysRole/add
 */
export function Api_SysRoleAdd(
  data: BaseIncCenterSysRoleAddVo,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterAdd["data"]> {
  return request_({
    url: `/base-inc-center/sysRole/add`,
    method: "POST",
    data: data,
    ...extraConfig,
  });
}

declare global {
  export type SysRoleUpdateDetailParams = Parameters<typeof Api_SysRoleUpdateDetail>["query"];
}
/**
 * No description
 * @tags 角色管理接口
 * @name Update7
 * @summary 修改前回显
 * @request GET:/sysRole/updateDetail
 */
export function Api_SysRoleUpdateDetail(
  query: BaseIncCenterUpdate7Params,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterUpdate7["data"]> {
  return request_({
    url: `/base-inc-center/sysRole/updateDetail`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}

declare global {
  export type SysRoleListParams = Parameters<typeof Api_SysRoleList>["query"];
}
/**
 * No description
 * @tags 角色管理接口
 * @name SelectPage
 * @summary 获取列表
 * @request GET:/sysRole/list
 */
export function Api_SysRoleList(
  query: BaseIncCenterSelectPageParams,
  extraConfig: ExtraConfig = {},
): Promise<BaseIncCenterSelectPage["data"]> {
  return request_({
    url: `/base-inc-center/sysRole/list`,
    method: "GET",
    params: query,
    ...extraConfig,
  });
}
