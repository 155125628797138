import type { ComputedRef } from 'vue'
import { Api_SysDictDataSelectListGroupByDicCode } from '@/api/__auto__/base-inc-center/SysDictData'
import { Api_InteriorLangPackSelectPage } from '@/api/__auto__/app-opt/Interior'
import { Api_InteriorAlertCodeExtSelectAll, Api_InteriorDtcCodeGetDtcLabel } from '@/api/__auto__/tsp-alert/Interior'
import { Api_InteriorAreaDataSelectList } from '@/api/__auto__/base-inc-center/Interior'

export const useDataDictionaryStore = defineStore('data-dictionary', () => {
  const dictMap = ref<
    Partial<Record<DataDictionaryCode, UniversalOption[] | 'pending' | 'failed'>>
  >({})

  const addendCodeList = ['lang', 'faultNotice', 'alertNotice', 'area']
  const appendData = async (code: DataDictionaryCode) => {
    if (code === 'lang') {
      const { list } = await Api_InteriorLangPackSelectPage({}, { hasLoading: false })
      const resp = { lang: list.map(item => ({ value: item.lang, label: item.name })) }
      dictMap.value[code] = resp[code]
      registerEnum(code, resp[code])
    }
    if (code === 'faultNotice') {
      const list = await Api_InteriorDtcCodeGetDtcLabel({ hasLoading: false })
      const resp = { faultNotice: list }
      dictMap.value[code] = resp[code]
      registerEnum(code, resp[code])
    }
    if (code === 'alertNotice') {
      const list = await Api_InteriorAlertCodeExtSelectAll({ hasLoading: false })
      const resp = { alertNotice: list.map(item => ({ value: item.alertCode, label: item.alertName })) }
      dictMap.value[code] = resp[code]
      registerEnum(code, resp[code])
    }
    if (code === 'area') {
      const list = await Api_InteriorAreaDataSelectList({ lang: localStorage.getItem('lang') || 'zh' }, { hasLoading: false })
      const resp = { alertNotice: list.map(item => ({ value: item.internationalNum, label: item.areaName })) }
      dictMap.value[code] = resp[code]
      registerEnum(code, resp[code])
    }
  }

  // 从后台获取字典值
  const queryDictByCode = async (code: DataDictionaryCode) => {
    try {
      dictMap.value[code] = 'pending'
      // 追加数据
      if (addendCodeList.includes(code)) {
        await appendData(code)
      }
      // 字典数据
      else {
        const resp = await Api_SysDictDataSelectListGroupByDicCode(
          {
            lang: localStorage.getItem('lang') || 'zh',
            dictCodeList: [code].join(','),
          },
          { hasLoading: false },
        )
        dictMap.value[code] = resp[code]
        registerEnum(code, resp[code])
      }
    }
    catch (e) {
      dictMap.value[code] = 'failed'
    }
    return dictMap.value[code] as UniversalOption[]
  }
  const queryAllDict = async () => {
    const dataDictionaryKeys = [...new Set(window.dataDictionaryKeys), ...addendCodeList] as DataDictionaryCode[]
    const localDataDict = JSON.parse(localStorage.getItem('data-dict') || '{}')
    dataDictionaryKeys.forEach((code: DataDictionaryCode) => {
      dictMap.value[code] = localDataDict[code] || 'pending'
    })
    const resp = await Api_SysDictDataSelectListGroupByDicCode(
      {
        lang: localStorage.getItem('lang') || 'zh',
        dictCodeList: dataDictionaryKeys.join(','),
      },
      { hasLoading: false },
    )
    dataDictionaryKeys.forEach((code: DataDictionaryCode) => {
      if (!addendCodeList.includes(code)) {
        dictMap.value[code] = resp[code]
        registerEnum(code, resp[code])
      }
    })

    // 追加数据
    await Promise.all([
      (async () => {
        const { list } = await Api_InteriorLangPackSelectPage({}, { hasLoading: false })
        resp.lang = list.map(item => ({ value: item.lang, label: item.name }))
        dictMap.value.lang = resp.lang
        registerEnum('lang', resp.lang)
      })(),
      (async () => {
        const faultNotice = await Api_InteriorDtcCodeGetDtcLabel({ hasLoading: false })
        resp.faultNotice = faultNotice
        dictMap.value.faultNotice = resp.faultNotice
        registerEnum('faultNotice', resp.faultNotice)
      })(),
      (async () => {
        const alertNotice = await Api_InteriorAlertCodeExtSelectAll({ hasLoading: false })
        resp.alertNotice = alertNotice.map(item => ({ value: item.alertCode, label: item.alertName }))
        dictMap.value.alertNotice = resp.alertNotice
        registerEnum('alertNotice', resp.alertNotice)
      })(),
      (async () => {
        const areaData = await Api_InteriorAreaDataSelectList({ lang: localStorage.getItem('lang') || 'zh' }, { hasLoading: false })
        resp.area = areaData.map(item => ({ value: item.internationalNum, label: item.areaName }))
        dictMap.value.area = resp.area
        registerEnum('area', resp.area)
      })(),
    ])
    localStorage.setItem('data-dict', JSON.stringify(resp))
  }
  queryAllDict().catch(e => console.error(e))

  return { dictMap, queryDictByCode, queryAllDict }
})

// 获取后台的字典值
export function getDictOptions(code: DataDictionaryCode): ComputedRef<UniversalOption[]> {
  const dictStore = useDataDictionaryStore()
  const { queryDictByCode } = dictStore
  const { dictMap } = storeToRefs(dictStore)

  return computed<UniversalOption[]>(() => {
    // 获取本地运行时缓存
    const options = dictMap.value[code]

    if ((['pending', 'failed'] as any[]).includes(options)) {
      // 如果当前code正在获取或获取失败则不重复请求
      return []
    }
    else if (options === undefined) {
      // 没有本地的缓存就去后台请求
      queryDictByCode(code)
      return []
    }
    else {
      return options as UniversalOption[]
    }
  })
}
