<script setup lang="tsx">
/**
 * @description 地址及经纬度选择器
 */
import { MapLocation } from '@element-plus/icons-vue'
import { useFormItem } from 'element-plus'
import { getDetailByLatLng } from './utils.tsx'
import { useCurrentPosition } from './store'
import type { AddressSelectorChangeResult } from './types'
import { UniMap } from './UniMap'

const props = defineProps({
  placeholder: { default: $t('components.map.addressSelectorTitle') }
})
const emits = defineEmits(["hook:mounted", "hook:updated", "hook:unmounted", "hook:beforeMount", "hook:beforeUpdate", "hook:beforeUnmount", "hook:errorCaptured", "hook:renderTracked", "hook:renderTriggered", "hook:activated", "hook:deactivated", "hook:serverPrefetch", "change", "blur"])

// 获取element的注入实例，用于处理校验
const { formItem } = useFormItem()
// 地址文本
const address = defineModel<string>('modelValue')
// 经度
const lng = defineModel<number | ''>('lng')
// 纬度
const lat = defineModel<number | ''>('lat')
const [visible, toggleVisible] = useToggle(false)

const { refreshCurrentPosition } = useCurrentPosition()

const mapEl = ref<HTMLDivElement>()
const mapInstance = shallowRef<UniMap>()
// 目的地址
const targetPosition = ref< google.maps.LatLngLiteral & { address: string }>()
async function setTarget(position: google.maps.LatLngLiteral & { address?: string }) {
  if (position.address) {
    targetPosition.value = position as google.maps.LatLngLiteral & { address: string }
    return
  }
  targetPosition.value = await getDetailByLatLng(position)
}

async function handleDialogOpen() {
  if (!mapInstance.value) {
    mapInstance.value = new UniMap(mapEl.value!, {
      mapId: 'c7491b85e45d25ca',
      zoom: 15,
      onAutocomplete(_e) {
        setTarget(_e.position)
      },
      onMapClick(_e) {
        setTarget(_e.position)
      },
    })
    mapInstance.value.createMarker({
      position: targetPosition.value,
      focus: true,
    })
  }
}

function handleConfirm() {
  address.value = targetPosition.value?.address ?? ''
  lat.value = targetPosition.value!.lat
  lng.value = targetPosition.value!.lng
  emits('change', targetPosition.value!)
  emits('blur')
  formItem?.validate?.('blur')
  toggleVisible(false)
}
async function show() {
  if (mapInstance.value) {
    mapInstance.value.autocompleteKeywords.value = ''
    mapInstance.value.clearMarker()
  }

  toggleVisible(true)
  if (lat.value && lng.value) {
    setTarget({
      lat: lat.value,
      lng: lng.value,
      address: address.value ?? '',
    })
  }
  else {
    setTarget(await refreshCurrentPosition())
  }
}

// 将搜索范围限制在目标点附近
watchEffect(async () => {
  if (mapInstance.value && targetPosition.value) {
    mapInstance.value.clearMarker()
    mapInstance.value.createMarker({
      position: targetPosition.value,
      focus: true,
    })
    mapInstance.value.bounds = {
      north: Number(targetPosition.value.lat) + 0.1,
      south: Number(targetPosition.value.lat) - 0.1,
      east: Number(targetPosition.value.lng) + 0.1,
      west: Number(targetPosition.value.lng) - 0.1,
    }
  }
})
</script>

<template>
  <div>
    <el-input
      readonly
      :model-value="address"
      :suffix-icon="MapLocation"
      :placeholder="props.placeholder"
      @click="show"
    />
    <nx-dialog
      v-model:visible="visible"
      :title="props.placeholder"
      width="60%"
      @confirm="handleConfirm"
      @opened="handleDialogOpen"
    >
      <div class="mb-2 text-base">
        <el-form label-width="auto">
          <el-form-item :label="`${$t('components.map.prev')}:`">
            <el-input v-model="address" readonly disabled placeholder="/" />
          </el-form-item>
          <el-form-item :label="`${$t('components.map.target')}:`">
            <el-input v-if="!targetPosition" v-model="address" placeholder="/" />
            <el-input v-else v-model="targetPosition.address" placeholder="/" />
          </el-form-item>
        </el-form>
      </div>
      <div ref="mapEl" style="height: 50vh;width: 100%" />
    </nx-dialog>
  </div>
</template>
