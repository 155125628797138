const modules = import.meta.glob('./*/enums.tsx', { eager: true }) as Recordable<
  Recordable<Recordable<UniversalOption[]>>
>

// 统一收集views下一级子目录的enums.tsx文件，注册枚举
export function registerViewsEnums() {
  const allModules = aggregateModuleExports<Recordable<UniversalOption[]>>(modules)
  // 以 模块名.组名 的形式命名key
  const flattenGroups = getFlattenGroups(allModules)

  batchRegisterEnums(flattenGroups)
  useDataDictionaryStore()
}

// 将二层嵌套的配置转化成一层的对象
export function getFlattenGroups(modules: Recordable<Recordable<UniversalOption[]>>) {
  return Object.entries(modules).reduce(
    (prev, [moduleName, groupObj]) => {
      Object.entries(groupObj).forEach(([key, group]) => {
        prev[`${moduleName}.${key}` as EnumGroupKey] = group
      })
      return prev
    },
    {} as Record<EnumGroupKey, UniversalOption[]>,
  )
}
