import { createVNode, render } from 'vue'
import { MarkerClusterer, type MarkerClustererOptions } from '@googlemaps/markerclusterer'
import { UniMarker } from './UniMarker'
import { UniPolyline } from './UniPolyline'
import { UniPolygon } from './UniPolygon'
import { UniCircle } from './UniCircle'
import './uni-map.scss'
import type { Poi, Position } from './types'

const isCn = isCnBuild()

export class UniMap {
  private gmpInstance?: google.maps.Map
  private aMapInstance?: AMap.Map
  private gmpAutocomplete?: google.maps.places.Autocomplete
  private aMapAutocomplete?: AMap.Autocomplete
  public markers = new Map<symbol, UniMarker>()
  public polylines = new Map<symbol, UniPolyline>()
  public polygons = new Map<symbol, UniPolygon>()
  public circles = new Map<symbol, UniCircle>()
  public autocompleteKeywords = ref('')
  constructor(
    containerEl: HTMLDivElement,
    options?: {
      center?: google.maps.LatLngLiteral
      mapId?: string
      /**
       * 定义边界，限制用户可访问的地图区域。设置后，用户只能在镜头视图保持在边界内时进行平移和缩放
       */
      restriction?: {
        latLngBounds: google.maps.LatLngBoundsLiteral
        strictBounds?: boolean
      }
      zoom?: number
      onAutocomplete?: (_e: {
        position: google.maps.LatLngLiteral
        map: UniMap
      }) => void
      onMapClick?: (_e: {
        position: google.maps.LatLngLiteral
        map: UniMap
      }) => void
    },
  ) {
    let autoCompleteInput: HTMLInputElement, autoCompleteDiv: HTMLDivElement
    if (options?.onAutocomplete) {
      autoCompleteDiv = document.createElement('div')
      autoCompleteDiv.style.position = 'fixed'
      autoCompleteDiv.style.zIndex = '9'
      containerEl.appendChild(autoCompleteDiv)
      const input = createVNode(() => (
        <div class="ml-2.5 mt-2.5 w-300px">
          <ElInput
            v-model={this.autocompleteKeywords.value}
            size="large"
            clearable
            placeholder={$t('monitoring.fenceInfo.addressSearch')}
          />
        </div>
      ))
      render(input, autoCompleteDiv)
      autoCompleteInput = autoCompleteDiv.querySelector('input')!
    }
    if (isCn) {
      this.aMapInstance = new AMap.Map(containerEl, {
        resizeEnable: true,
        zoomEnable: true,
        ...(options ?? {}),
        center: options?.center ? new AMap.LngLat(options.center.lng, options.center.lat) : undefined,
      })
      if (options?.onMapClick) {
        this.aMapInstance.on('click', (e: { lnglat: AMap.LngLat }) => {
          options.onMapClick!({
            position: {
              lng: e.lnglat.getLng(),
              lat: e.lnglat.getLat(),
            },
            map: this,
          })
        })
      }
      if (options?.onAutocomplete) {
        this.aMapAutocomplete = new AMap.Autocomplete({
          input: autoCompleteInput!,
        })
        this.aMapAutocomplete.on('select', (e: { poi: Poi }) => {
          options.onAutocomplete!({
            position: {
              lat: e.poi.location.getLat(),
              lng: e.poi.location.getLng(),
            },
            map: this,
          })
        })
      }
    }
    else {
      if (!options?.center)
        delete options?.center

      this.gmpInstance = new google.maps.Map(containerEl, {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        ...(options ?? {}),
      })
      // 谷歌地图需要手动设置地图中心点
      if (!options?.center) {
        useCurrentPosition().refreshCurrentPosition().then((current) => {
          this.center = current
        })
      }
      if (options?.onMapClick) {
        this.gmpInstance.addListener('click', (e: { latLng: google.maps.LatLng }) => {
          options.onMapClick!({
            position: e.latLng.toJSON(),
            map: this,
          })
        })
      }
      if (options?.onAutocomplete) {
        this.gmpAutocomplete = new google.maps.places.Autocomplete(
          autoCompleteInput!,
          {
            fields: ['geometry', 'formatted_address'],
            strictBounds: true,
          },
        )
        this.gmpInstance!.controls[google.maps.ControlPosition.TOP_LEFT].push(autoCompleteDiv!)
        google.maps.event.addListener(this.gmpAutocomplete, 'place_changed', () => {
          const place = this.gmpAutocomplete?.getPlace()
          options.onAutocomplete!({
            position: place!.geometry!.location!.toJSON(),
            map: this,
          })
        })
      }
    }
  }

  get bounds(): google.maps.LatLngBoundsLiteral | undefined {
    if (isCn) {
      const bounds = this.aMapInstance!.getBounds()
      const northEast = bounds.getNorthEast()
      const southWest = bounds.getSouthWest()
      return {
        east: northEast.getLat(),
        west: southWest.getLat(),
        north: northEast.getLng(),
        south: southWest.getLng(),
      }
    }
    else {
      return this.gmpInstance!.getBounds()?.toJSON()
    }
  }

  set bounds(bounds: google.maps.LatLngBoundsLiteral | google.maps.LatLngLiteral[]) {
    if (isCn) { /* 高德地图待实现 */ }
    else {
      if (Array.isArray(bounds)) {
        const _bounds = new google.maps.LatLngBounds()
        bounds.forEach(loc => _bounds.extend(loc))
        this.gmpInstance!.fitBounds(_bounds)
      }
      else {
        this.gmpInstance!.fitBounds(bounds)
      }
    }
  }

  get center(): google.maps.LatLngLiteral | undefined {
    if (isCn) {
      const _center = this.aMapInstance!.getCenter()
      return {
        lat: _center.getLat(),
        lng: _center.getLng(),
      }
    }
    else { return this.gmpInstance!.getCenter()?.toJSON() }
  }

  set center(position: google.maps.LatLngLiteral) {
    if (isCn)
      this.aMapInstance!.setCenter(new AMap.LngLat(position.lng, position.lat))

    else
      this.gmpInstance!.setCenter(position)
  }

  get zoom(): number | undefined {
    if (isCn)
      return this.aMapInstance!.getZoom()

    else
      return this.gmpInstance!.getZoom()
  }

  set zoom(_num: number) {
    if (isCn)
      this.aMapInstance!.setZoom(_num)

    else
      this.gmpInstance!.setZoom(_num)
  }

  createMarker(options: {
    position: google.maps.LatLngLiteral
    icon?: google.maps.Icon | google.maps.Symbol
    focus?: boolean
  }): {
      key: symbol
      marker: UniMarker
    } {
    const marker = new UniMarker({
      ...options,
      map: this.gmpInstance || this.aMapInstance,
    }, {
      focus: options.focus,
    })
    const markerSymbol = Symbol('marker')
    this.markers.set(markerSymbol, marker)
    return { key: markerSymbol, marker }
  }

  removeMarker(markerSymbol: symbol) {
    const marker = this.markers.get(markerSymbol)
    if (!marker)
      return false
    marker.setMap(null)
    this.markers.delete(markerSymbol)
  }

  clearMarker() {
    this.markers.forEach(marker => marker.setMap(null))
    this.markers.clear()
  }

  createPolyline(options: {
    path: google.maps.LatLngLiteral[]
    focus?: boolean
  }): {
      key: symbol
      polyline: UniPolyline
    } {
    if (isCn) {
      return Symbol('polyline')
    }
    else {
      const polyline = new UniPolyline({
        map: this.gmpInstance!,
        path: options.path,
      }, { focus: options.focus })
      const polylineSymbol = Symbol('polyline')
      this.polylines.set(polylineSymbol, polyline)
      return {
        key: polylineSymbol,
        polyline,
      }
    }
  }

  closePolyline(polylineSymbol: symbol) {
    const polyline = this.polylines.get(polylineSymbol)
    if (!polyline)
      return false
    polyline.destroy()
    this.polylines.delete(polylineSymbol)
  }

  openPolygonEditor(options?: {
    path?: google.maps.LatLngLiteral[]
  }, callback?: ((arg0: google.maps.LatLngLiteral[]) => void) | undefined) {
    const center = this.center!
    const defaultPath = [
      { lng: center.lng - 0.05, lat: center.lat + 0.03 },
      { lng: center.lng + 0.05, lat: center.lat + 0.03 },
      { lng: center.lng + 0.05, lat: center.lat - 0.03 },
      { lng: center.lng - 0.05, lat: center.lat - 0.03 },
    ]
    const polygon = new UniPolygon({ path: options?.path || defaultPath, map: (this.gmpInstance || this.aMapInstance)! }, {})
    const polygonSymbol = Symbol('polygon')
    this.polygons.set(polygonSymbol, polygon)
    polygon.polygonEditor(callback)
    return { key: polygonSymbol, polygon }
  }

  getPolygonPath(polygonSymbol: symbol) {
    const polygon = this.polygons.get(polygonSymbol)
    if (!polygon)
      return false
    return polygon.getPath() as Position[]
  }

  closePolygonEditor(polygonSymbol: symbol) {
    const polygon = this.polygons.get(polygonSymbol)
    if (!polygon)
      return false
    polygon.destroy()
    this.polygons.delete(polygonSymbol)
  }

  openCircleEditor(options?: {
    center?: google.maps.LatLngLiteral
    radius?: number
  }, callback?: (() => void)) {
    const center = options?.center || this.center!
    const circle = new UniCircle({ center, radius: options?.radius || 1, map: (this.gmpInstance || this.aMapInstance)! }, {})
    const circleSymbol = Symbol('circle')
    this.circles.set(circleSymbol, circle)
    circle.circleEditor(callback)
    return { key: circleSymbol, circle }
  }

  getCenterRadius(circleSymbol: symbol) {
    const circle = this.circles.get(circleSymbol)
    if (!circle)
      return false
    return circle.getCenterRadius()
  }

  setRadius(circleSymbol: symbol, radius: number) {
    const circle = this.circles.get(circleSymbol)
    if (!circle)
      return false
    return circle.setRadius(radius)
  }

  closeCircleEditor(circleSymbol: symbol) {
    const circle = this.circles.get(circleSymbol)
    if (!circle)
      return false
    circle.destroy()
    this.circles.delete(circleSymbol)
  }

  markerClusterer(params: MarkerClustererOptions) {
    return new MarkerClusterer({ map: this.gmpInstance, ...params })
  }
}
