/**
 * @title 个人中心*
 * @description 本文件由插件自动生成
 */

import request_, { type ExtraConfig } from "@/utils/request";

declare global {}
/**
 * No description
 * @tags 个人中心
 * @name MyInfo
 * @summary 个人信息
 * @request GET:/v1/web/home/user/myInfo
 */
export function Api_V1WebHomeUserMyInfo(extraConfig: ExtraConfig = {}): Promise<BaseIncCenterMyInfo["data"]> {
  return request_({
    url: `/base-inc-center/v1/web/home/user/myInfo`,
    method: "GET",
    ...extraConfig,
  });
}
