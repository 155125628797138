export const useLoadingStore = defineStore('loading-store', () => {
  const loadingCount = ref(0) // 全局loading计数 增加loading时增加，去除时减少，归零时不显示loading
  const loading = computed(() => loadingCount.value > 0)
  const loadingInstance = ref<ReturnType<(typeof ElLoading)['service']>>()
  const addLoading = () => {
    loadingCount.value++
    if (import.meta.env.VITE_APP_NAME === 'admin')
      loadingInstance.value = ElLoading.service()
  }
  const subtractLoading = () => {
    loadingCount.value--
    if (loadingCount.value <= 0)
      loadingInstance.value?.close()
  }

  return { loading, addLoading, subtractLoading }
})

export const addLoading = () => useLoadingStore().addLoading()
export const subtractLoading = () => useLoadingStore().subtractLoading()
